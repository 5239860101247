import { mockApi } from '../../../api/constants';
import { HttpRequest } from '../../../api/HttpRequest';
import { Project, ProjectInput } from '../../../dto';
import { FakeRequest } from '../../../api/FakeRequest';
import { serializer } from '../../../dto/utils';

export const createProjectRequest = !mockApi
    ? new HttpRequest<ProjectInput, Project>({
          method: 'post',
          path: () => '/project',
          mapper: data => serializer.deserializeObject(data, Project) as Project,
          body: data => serializer.serializeObject(data) || {},
      })
    : new FakeRequest<ProjectInput, Project>({
          fakeData: () =>
              serializer.deserializeObject(
                  {
                      id: '0',
                      name: 'New project',
                      from: '01.01.2024',
                      to: '31.10.2024',
                      participants: [],
                  },
                  Project
              ) as Project,
      });
