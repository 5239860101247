import { Layout } from '../../components/Layout';
import { Employees } from './Employees';
import React, { FC } from 'react';

export const EmployeesPage: FC = () => {
    return (
        <Layout title="Сотрудники">
            <Employees />
        </Layout>
    );
};
