import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Button, LinearProgress, Paper, Stack, styled, Typography } from '@mui/material';
import { Layout } from '../../components/Layout';
import { authStore } from '../../stores/authStore';
import { ResetPasswordInput, ResetPasswordStatus } from '../../dto';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { checkResetPasswordCodeRequest } from './checkResetPasswordCodeRequest';
import { Form, Formik } from 'formik';
import { getResetPasswordFormik } from './ResetPasswordFormik';
import { messageStore } from '../../stores/messageStore';
import { resetPasswordRequest } from './resetPasswordRequest';
import { useNavigate } from 'react-router';
import { FormikPasswordField } from '../../components/FormikFields';

const Root = styled(Paper)(({ theme }) => ({
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5),
    },
}));

const ButtonArea = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
});

const title = 'Сброс пароля';

export const ResetPasswordPage: FC = observer(() => {
    const [params, _setParams] = useSearchParams();

    const code = params.get('code') || '';

    const [result, setResult] = useState<ResetPasswordStatus | null>(null);

    const [error, setError] = useState(false);

    const navigate = useNavigate();

    const needProcess = code && !authStore.isLoggedIn;

    useEffect(() => {
        if (!needProcess) return;

        checkResetPasswordCodeRequest
            .request({ code })
            .then(value => setResult(value.status))
            .catch(() => setError(true));
    }, [code, needProcess, setResult]);

    const reset = useCallback(
        async (input: ResetPasswordInput) =>
            resetPasswordRequest
                .request(input)
                .then(() => messageStore.success('Пароль успешно обновлен'))
                .then(() => navigate('/')),
        [navigate]
    );

    if (!needProcess)
        return (
            <Layout title={title} actions={authStore.isLoggedIn ? 'full' : 'none'} showSelector={false}>
                <Root>
                    <Typography>
                        Для сброса пароля не должно быть активной сессии и должен быть указан код из письма.
                    </Typography>
                </Root>
            </Layout>
        );

    if (checkResetPasswordCodeRequest.isLoading)
        return (
            <Layout title={title} actions={'none'} showSelector={false}>
                <Root>
                    <LinearProgress />
                </Root>
            </Layout>
        );

    if (!result || result === ResetPasswordStatus.UNKNOWN || error)
        return (
            <Layout title={title} actions={'none'} showSelector={false}>
                <Root>
                    <Root>
                        <Typography color="error">Не удалось сбросить пароль.</Typography>
                    </Root>
                </Root>
            </Layout>
        );

    if (result === ResetPasswordStatus.USED)
        return (
            <Layout title={title} actions={'none'} showSelector={false}>
                <Root>
                    <Root>
                        <Typography>Код для сброса пароля уже использован.</Typography>
                    </Root>
                </Root>
            </Layout>
        );

    return (
        <Layout title={title} actions={'none'} showSelector={false}>
            <Root>
                <Formik {...getResetPasswordFormik(code, reset)}>
                    {formik => (
                        <Form>
                            <Stack spacing={2} flexDirection="column">
                                <FormikPasswordField
                                    name={'password'}
                                    formik={formik}
                                    disabled={formik.isSubmitting}
                                    label="Пароль"
                                />
                                <FormikPasswordField
                                    name={'confirmPassword'}
                                    formik={formik}
                                    disabled={formik.isSubmitting}
                                    label="Подтверждение"
                                />
                                <ButtonArea>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={formik.isSubmitting || !formik.touched || !formik.isValid}
                                    >
                                        Сохранить
                                    </Button>
                                </ButtonArea>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Root>
        </Layout>
    );
});
