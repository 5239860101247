import { JsonObject } from 'typescript-json-serializer';
import { JsonEnum } from './utils';

export enum ResetPasswordStatus {
    ACTIVATED = 'ACTIVATED',
    USED = 'USED',
    UNKNOWN = 'UNKNOWN',
}

@JsonObject()
export class ResetPasswordResult {
    @JsonEnum({ type: ResetPasswordStatus })
    status: ResetPasswordStatus = ResetPasswordStatus.UNKNOWN;
}
