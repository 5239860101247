import { action, makeObservable, observable } from 'mobx';
import { ProjectDetails } from '../dto';
import { projectDetailsRequest } from '../views/ProjectDetailsPage/projectDetailsRequest';

class ProjectDetailsStore {
    constructor() {
        makeObservable(this);
    }

    @observable
    data?: ProjectDetails;

    @observable
    isLoading = false;

    @observable
    error: Error | null = null;

    @action refresh = (id: string) => {
        this.isLoading = true;
        this.error = null;

        projectDetailsRequest
            .request({ id })
            .then(result => (this.data = result))
            .catch(error => (this.error = error))
            .finally(() => {
                this.isLoading = false;
            });
    };
}

export const projectDetailsStore = new ProjectDetailsStore();
