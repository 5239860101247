import { Availability } from '../dto';
import { DisplayAvailability } from './types';
import moment from 'moment';

export const squashAvailability = (array: Availability[]): DisplayAvailability[] => {
    return array.reduce((acc: DisplayAvailability[], el, ind) => {
        const lastEl = acc.length > 0 ? acc[acc.length - 1] : null;

        if (ind === 0 || (lastEl && moment(el.from).diff(moment(lastEl.to), 'day') > 1)) {
            acc.push({
                from: el.from,
                to: el.to,
                minPercent: el.percent || null,
                maxPercent: el.percent || null,
            });
        } else if (lastEl) {
            lastEl.to = el.to;
            lastEl.maxPercent =
                el.percent === null
                    ? lastEl.maxPercent
                    : (el.percent || 0) > (lastEl.maxPercent || 0)
                      ? el.percent || null
                      : lastEl.maxPercent;
            lastEl.minPercent =
                el.percent === null
                    ? lastEl.minPercent
                    : (el.percent || 0) < (lastEl.minPercent || 0)
                      ? el.percent || null
                      : lastEl.minPercent;
        }

        return acc;
    }, []);
};
