import { FC, useCallback, useState } from 'react';
import { Layout } from '../../components/Layout';
import { Form, Formik } from 'formik';
import { getRegisterFormik } from './RegisterFormik';
import { Box, Button, Paper, Stack, styled } from '@mui/material';
import { FormikPasswordField, FormikTextField } from '../../components/FormikFields';
import { RegisterInput } from '../../dto';
import { registerRequest } from './registerRequest';
import { messageStore } from '../../stores/messageStore';
import { RegisterCompleteInfo } from './RegisterCompleteInfo';

const Root = styled(Paper)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    boxSizing: 'border-box',
}));

const ButtonArea = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
});

export const RegisterPage: FC = () => {
    const [complete, setComplete] = useState(false);

    const register = useCallback(
        async (input: RegisterInput) =>
            registerRequest
                .request(input)
                .then(() => messageStore.success('Пользователь зарегистрирован'))
                .then(() => setComplete(true)),
        [setComplete]
    );

    return (
        <Layout title="Регистрация" actions="none" showSelector={false}>
            <Root>
                {complete ? (
                    <RegisterCompleteInfo />
                ) : (
                    <Formik {...getRegisterFormik(register)}>
                        {formik => (
                            <Form>
                                <Stack spacing={2} flexDirection="column">
                                    <FormikTextField
                                        name={'email'}
                                        formik={formik}
                                        disabled={formik.isSubmitting}
                                        label="Email"
                                    />
                                    <FormikPasswordField
                                        name={'password'}
                                        formik={formik}
                                        disabled={formik.isSubmitting}
                                        label="Пароль"
                                    />
                                    <FormikPasswordField
                                        name={'confirmPassword'}
                                        formik={formik}
                                        disabled={formik.isSubmitting}
                                        label="Подтверждение"
                                    />
                                    <ButtonArea>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            disabled={formik.isSubmitting || !formik.touched || !formik.isValid}
                                        >
                                            Регистрация
                                        </Button>
                                    </ButtonArea>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                )}
            </Root>
        </Layout>
    );
};
