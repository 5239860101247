import { FakeRequest } from '../../api/FakeRequest';
import { Employee } from '../../dto';
import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';
import { serializer } from '../../dto/utils';

export const employeesRequest = !mockApi
    ? new HttpRequest<{ includeArchive: boolean }, Employee[]>({
          method: 'get',
          path: input =>
              `/employee?${new URLSearchParams({
                  includeArchive: input.includeArchive.toString(),
              })}`,
          mapper: data => serializer.deserializeObjectArray(data, Employee) as Employee[],
      })
    : new FakeRequest<{ includeArchive: boolean }, Employee[]>({
          fakeData: () =>
              serializer.deserializeObjectArray(
                  [
                      {
                          id: '11',
                          name: 'Jane',
                          surname: 'Doe',
                          hasPhoto: false,
                      },
                      {
                          id: '12',
                          name: 'John',
                          surname: 'Doe',
                          hasPhoto: false,
                      },
                      {
                          id: '21',
                          name: 'Peter',
                          surname: 'Parker',
                          hasPhoto: false,
                      },
                  ],
                  Employee
              ) as Employee[],
      });
