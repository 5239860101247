import React, { FC, useCallback } from 'react';
import { Box, Chip, IconButton, Stack, styled, Typography } from '@mui/material';
import { Archive, Edit, Restore } from '@mui/icons-material';
import { EmployeeBadge } from '../EmployeeBadge';
import { formatFullName } from '../../utils/formatters';
import { DisplayEmployee, Size } from '../../utils/types';
import { useMobileLayout } from '../../hooks';
import { ColorChip } from '../ColorChip';

type LabelInfo = {
    label: string;
    color?: string;
    back: string;
};

type Props = {
    employee: DisplayEmployee;
    showButtons?: boolean;
    onEdit?: () => void;
    onArchive?: () => void;
    onRestore?: () => void;
    clickable?: boolean;
    disabled?: boolean;
    size?: Size;
    labels?: LabelInfo[];
};

const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    width: '100%',
}));

type FieldsProps = {
    size: Size;
};

const EmployeeFields = styled('div')<FieldsProps>(({ theme, size }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: size === 'normal' ? theme.spacing(3) : theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: theme.spacing(1),
        alignItems: 'flex-start',
    },
}));

const FieldGroup = styled('div')<FieldsProps>(({ theme, size }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: size === 'normal' ? theme.spacing(3) : theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
    },
}));

type ButtonAreaProps = {
    visible: boolean;
};

const ButtonArea = styled('div')<ButtonAreaProps>(({ theme, visible }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    visibility: visible ? 'visible' : 'hidden',
    [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(0.5),
    },
}));

export const EmployeeInfo: FC<Props> = ({
    employee,
    showButtons = false,
    onEdit,
    onArchive,
    onRestore,
    clickable,
    disabled = false,
    size = 'normal',
    labels,
}) => {
    const isMobile = useMobileLayout();

    const onEditClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();

            if (onEdit) {
                onEdit();
            }
        },
        [onEdit]
    );

    const onArchiveClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();

            if (onArchive) {
                onArchive();
            }
        },
        [onArchive]
    );

    const onRestoreClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();

            if (onRestore) {
                onRestore();
            }
        },
        [onRestore]
    );

    return (
        <Root>
            <EmployeeFields size={size}>
                <FieldGroup size={size}>
                    <EmployeeBadge employee={employee} clickable={clickable} size={size} disabled={disabled} />
                    <Typography variant={size === 'normal' && !isMobile ? 'h5' : 'body1'}>
                        {formatFullName(employee)}
                    </Typography>
                </FieldGroup>
                {employee.isArchived ||
                    (labels && labels.length > 0 && (
                        <Stack spacing={1} direction="row">
                            {employee.isArchived && <Chip label={'В архиве'} color="primary" size="small" />}
                            {labels &&
                                labels.map(l => (
                                    <ColorChip
                                        key={l.label}
                                        label={l.label}
                                        customfontcolor={l.color}
                                        custombackcolor={l.back}
                                        size="small"
                                    />
                                ))}
                        </Stack>
                    ))}
            </EmployeeFields>
            <ButtonArea visible={showButtons}>
                <IconButton onClick={onEditClick} disabled={disabled || employee.isArchived}>
                    <Edit />
                </IconButton>
                {employee.isArchived ? (
                    <IconButton onClick={onRestoreClick} disabled={disabled}>
                        <Restore />
                    </IconButton>
                ) : (
                    <IconButton onClick={onArchiveClick} disabled={disabled}>
                        <Archive />
                    </IconButton>
                )}
            </ButtonArea>
        </Root>
    );
};
