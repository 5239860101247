import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';
import { serializer } from '../../dto/utils';
import { FakeRequest } from '../../api/FakeRequest';

export const resetPasswordRequest = !mockApi
    ? new HttpRequest<{ email: string }, unknown>({
          method: 'post',
          path: () => '/user/requestPasswordReset',
          mapper: data => data,
          body: data => serializer.serializeObject(data) || {},
      })
    : new FakeRequest<{ email: string }, unknown>({
          fakeData: () => {},
      });
