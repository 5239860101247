import { mockApi } from '../../../api/constants';
import { HttpRequest } from '../../../api/HttpRequest';
import { Employee, EmployeeInput } from '../../../dto';
import { FakeRequest } from '../../../api/FakeRequest';
import { serializer } from '../../../dto/utils';

export const updateEmployeeRequest = !mockApi
    ? new HttpRequest<EmployeeInput, Employee>({
          method: 'put',
          path: () => '/employee',
          mapper: data => serializer.deserializeObject(data, Employee) as Employee,
          body: data => serializer.serializeObject(data) || {},
      })
    : new FakeRequest<EmployeeInput, Employee>({
          fakeData: () =>
              serializer.deserializeObject(
                  {
                      id: '0',
                      name: 'Иван',
                      patronymic: 'Константинович',
                      surname: 'Образец',
                  },
                  Employee
              ) as Employee,
      });
