import { Box, LinearProgress, Paper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { styled } from '@mui/material';
import React, { FC, useMemo, useState } from 'react';
import { useRequest } from '../../../hooks';
import { employeesAvailabilityRequest } from './employeesAvailabilityRequest';
import { AvailabilityCard } from './AvailabilityCard';
import moment from 'moment';
import { squashAvailability } from '../../../utils/availability';
import { AvailabilityInterval } from '../widget.types';
import { NoDataPlaceholder } from '../../../components/NoDataPlaceholder';

const Root = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
}));

const Container = styled(Box)({
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
});

const Header = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

type AvailabilityPercent = 0.25 | 0.5;

export const AvailabilityWidget: FC = () => {
    const [percent, setPercent] = useState<AvailabilityPercent>(0.25);

    const { data, error, loading } = useRequest(employeesAvailabilityRequest, { percent: 1 - percent });

    const [interval, setInterval] = useState<AvailabilityInterval>('month');

    const items = useMemo(() => {
        if (!data) return [];

        const now = moment(new Date());
        const availableItems = data
            .map(item => ({
                ...item,
                availability: item.availability.filter(
                    a =>
                        moment(a.from).diff(now, interval === 'week' ? 'days' : 'months', true) <=
                        (interval === 'week' ? 7 : interval === 'month' ? 1 : 3)
                ),
            }))
            .filter(item => item.availability.length > 0)
            .map(item => ({
                ...item,
                availability: squashAvailability(item.availability),
            }));

        availableItems.sort((a, b) => +a.availability[0].from - +b.availability[0].from);

        return availableItems;
    }, [data, interval]);

    const onIntervalChange = (_event: React.MouseEvent<HTMLElement>, newValue: AvailabilityInterval) => {
        setInterval(newValue);
    };

    const onPercentChange = (_event: React.MouseEvent<HTMLElement>, newValue: AvailabilityPercent) => {
        setPercent(newValue);
    };

    return (
        <Root>
            <Typography variant="h6">Доступность</Typography>
            <Header>
                <ToggleButtonGroup
                    value={interval}
                    onChange={onIntervalChange}
                    color="primary"
                    exclusive
                    disabled={loading}
                >
                    <ToggleButton value={'week'} disabled={interval === 'week'}>
                        Неделя
                    </ToggleButton>
                    <ToggleButton value={'month'} disabled={interval === 'month'}>
                        Месяц
                    </ToggleButton>
                    <ToggleButton value={'quarter'} disabled={interval === 'quarter'}>
                        Квартал
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                    value={percent}
                    onChange={onPercentChange}
                    color="primary"
                    exclusive
                    disabled={loading}
                >
                    <ToggleButton value={0.25} disabled={percent === 0.25}>
                        25%
                    </ToggleButton>
                    <ToggleButton value={0.5} disabled={percent === 0.5}>
                        50%
                    </ToggleButton>
                </ToggleButtonGroup>
            </Header>
            {loading && <LinearProgress />}
            {error && <Typography color="error">{error.message}</Typography>}
            {!loading && items.length === 0 && <NoDataPlaceholder />}
            <Container>
                {items.map(item => (
                    <AvailabilityCard item={item} key={item.id} />
                ))}
            </Container>
        </Root>
    );
};
