import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { WorkloadRow } from './WorkloadRow';
import { DisplayEmployee, DisplayProject } from '../../utils/types';
import { Workload } from '../../dto/WorkloadDto';
import { WorkloadInput } from '../../dto';
import { NoDataPlaceholder } from '../NoDataPlaceholder';

export type WorkloadRowInfo = {
    project: DisplayProject;
    employee: DisplayEmployee;
    workload: Workload;
};

type Props = {
    data: WorkloadRowInfo[];
    onRemove: (row: WorkloadRowInfo) => void;
    onCreate: (input: WorkloadInput) => Promise<void>;
    onUpdate: (input: WorkloadInput) => Promise<void>;
    renderSection: (row: WorkloadRowInfo) => ReactNode;
    sectionHeader: string;
    options: {
        allowProjectEdit?: boolean;
        allowEmployeeEdit?: boolean;
    };
    rowId?: string;
    setRowId: (id: string) => void;
    disabled?: boolean;
};

const WorkloadTableContainer = styled(TableContainer)({
    height: 'calc(50vh - 120px)',
});

const TableHeader = styled(TableHead)({
    position: 'sticky',
    top: 0,
    background: '#FFFFFF',
});

export const WorkloadTable: FC<Props> = ({
    data,
    onRemove,
    onCreate,
    onUpdate,
    renderSection,
    sectionHeader,
    options,
    rowId,
    setRowId,
    disabled = false,
}) => {
    const [activeId, setActiveId] = useState<string | null>(null);

    const rowRefs = useRef(new Map<string, HTMLTableRowElement>());

    useEffect(() => {
        if (!rowId) return;
        const element = rowRefs.current.get(rowId);
        if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }, [rowId]);

    const saveRef = (id: string, element: HTMLTableRowElement | null) => {
        if (element) {
            rowRefs.current.set(id, element);
        }
    };

    if (data.length === 0) return <NoDataPlaceholder />;

    return (
        <WorkloadTableContainer>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableCell>{sectionHeader}</TableCell>
                        <TableCell>С</TableCell>
                        <TableCell>По</TableCell>
                        <TableCell>Загрузка</TableCell>
                        <TableCell>Примечание</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {data.map(item => (
                        <WorkloadRow
                            key={item.workload.id}
                            project={item.project}
                            employee={item.employee}
                            workload={item.workload}
                            isActive={item.workload.id === activeId}
                            onActivate={() => setActiveId(item.workload.id)}
                            onDeactivate={() => setActiveId(null)}
                            renderSection={() => renderSection(item)}
                            onRemove={() => onRemove(item)}
                            onCreate={onCreate}
                            onUpdate={onUpdate}
                            options={options}
                            createRef={(el: HTMLTableRowElement | null) => saveRef(item.workload.id, el)}
                            isSelected={item.workload.id === rowId}
                            onClick={() => setRowId(item.workload.id)}
                            disabled={disabled}
                        />
                    ))}
                </TableBody>
            </Table>
        </WorkloadTableContainer>
    );
};
