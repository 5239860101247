import React, { FC, useCallback } from 'react';
import { WorkloadInput } from '../../../dto';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid2,
    LinearProgress,
    styled,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { FormikDateField, FormikNumberField, FormikTextField } from '../../../components/FormikFields';
import { getWorkloadConfig } from './WorkloadFormik';
import { EmployeeSelect } from '../../../components/EmployeeSelect';
import { DisplayEmployee, DisplayProject } from '../../../utils/types';
import { Workload } from '../../../dto/WorkloadDto';
import { ProjectSelect } from '../../../components/ProjectSelect/ProjectSelect';
import { planningModeStore } from '../../../stores/planningModeStore';

type Props = {
    project?: DisplayProject;
    employee?: DisplayEmployee;
    workload?: Workload;
    open: boolean;
    onClose: () => void;
    onCreate: (input: WorkloadInput) => Promise<void>;
    onUpdate: (input: WorkloadInput) => Promise<void>;
    options: {
        allowProjectEdit?: boolean;
        allowEmployeeEdit?: boolean;
    };
};

const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}));

const FirstGridRow = styled(Grid2)(({ theme }) => ({
    paddingTop: theme.spacing(1),
}));

export const WorkloadForm: FC<Props> = ({
    project,
    employee,
    workload,
    open,
    onClose,
    onCreate,
    onUpdate,
    options,
}) => {
    const onCreateWorkload = useCallback(
        async (input: WorkloadInput) => {
            return onCreate(input).then(onClose);
        },
        [onCreate, onClose]
    );

    const onUpdateWorkload = useCallback(
        async (input: WorkloadInput) => {
            return onUpdate(input).then(onClose);
        },
        [onUpdate, onClose]
    );

    return (
        <Formik
            {...getWorkloadConfig(
                workload ? onUpdateWorkload : onCreateWorkload,
                planningModeStore.mode,
                project,
                employee,
                workload
            )}
        >
            {formik => (
                <Dialog open={open} maxWidth="md" fullWidth>
                    <Form>
                        <DialogTitle>{workload ? 'Изменить загрузку' : 'Добавить загрузку'}</DialogTitle>
                        <DialogContent>
                            <Root>
                                {formik.isSubmitting && <LinearProgress />}
                                <Grid2 container spacing={2}>
                                    <FirstGridRow size={12}>
                                        <EmployeeSelect
                                            selectedId={formik.values.employeeId}
                                            onChange={value => formik.setFieldValue('employeeId', value.id, true)}
                                            error={formik.errors.employeeId}
                                            disabled={!options.allowEmployeeEdit || formik.isSubmitting}
                                        />
                                    </FirstGridRow>
                                    <Grid2 size={12}>
                                        <ProjectSelect
                                            selectedId={formik.values.projectId}
                                            onChange={value => formik.setFieldValue('projectId', value.id, true)}
                                            error={formik.errors.projectId}
                                            disabled={!options.allowProjectEdit || formik.isSubmitting}
                                        />
                                    </Grid2>
                                    <Grid2 size={6} paddingTop={2}>
                                        <FormikDateField
                                            name={'from'}
                                            formik={formik}
                                            label="Дата начала"
                                            required
                                            disabled={formik.isSubmitting}
                                        />
                                    </Grid2>
                                    <Grid2 size={6} paddingTop={2}>
                                        <FormikDateField
                                            name={'to'}
                                            formik={formik}
                                            label="Дата завершения"
                                            required
                                            disabled={formik.isSubmitting}
                                        />
                                    </Grid2>
                                    {planningModeStore.mode === 'percents' ? (
                                        <Grid2 size={3}>
                                            <FormikNumberField
                                                name={'percent'}
                                                formik={formik}
                                                label="Процент"
                                                required
                                                disabled={formik.isSubmitting}
                                            />
                                        </Grid2>
                                    ) : (
                                        <Grid2 size={3}>
                                            <FormikNumberField
                                                name={'hours'}
                                                formik={formik}
                                                label="Часы"
                                                required
                                                disabled={formik.isSubmitting}
                                            />
                                        </Grid2>
                                    )}
                                    <Grid2 size={9}>
                                        <FormikTextField
                                            name={'comment'}
                                            formik={formik}
                                            label="Комментарий"
                                            disabled={formik.isSubmitting}
                                        />
                                    </Grid2>
                                </Grid2>
                                <Divider />
                            </Root>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    formik.resetForm();
                                    onClose();
                                }}
                                disabled={formik.isSubmitting}
                            >
                                Отмена
                            </Button>
                            <Button variant="contained" type="submit" disabled={formik.isSubmitting}>
                                Сохранить
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            )}
        </Formik>
    );
};
