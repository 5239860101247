import { Box, LinearProgress, styled, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Layout } from '../../components/Layout';
import { employeeDetailsStore } from '../../stores/employeeDetailsStore';
import { EmployeeDetails } from './EmployeeDetails';
import { formatFullName } from '../../utils/formatters';

const Root = styled(Box)({
    width: '100%',
});

export const EmployeeDetailsPage: FC = observer(() => {
    const { employeeId } = useParams();

    useEffect(() => {
        employeeDetailsStore.refresh(employeeId || '');
    }, [employeeId]);

    const data = employeeDetailsStore.data;

    return (
        <Layout title={data ? formatFullName(data) : ''}>
            <Root>
                {employeeDetailsStore.error && (
                    <Typography color="error">{employeeDetailsStore.error.message}</Typography>
                )}
                {employeeDetailsStore.isLoading && <LinearProgress />}
                {data && <EmployeeDetails data={data} />}
            </Root>
        </Layout>
    );
});
