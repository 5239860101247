import React, { FC, useCallback } from 'react';
import { Box, IconButton, styled, Typography } from '@mui/material';
import { Archive, Edit, Restore } from '@mui/icons-material';
import { DateInterval } from '../DateInterval';
import { getProjectLogoUrl } from '../../utils/project';
import { DisplayProject } from '../../utils/types';
import { useMobileLayout } from '../../hooks';

type Props = {
    project: DisplayProject;
    showButtons?: boolean;
    onEdit?: () => void;
    onArchive?: () => void;
    onRestore?: () => void;
    disabled?: boolean;
};

const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
}));

const ProjectFields = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}));

const FieldsBlock = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
    },
}));

const Logo = styled('img')(({ theme }) => ({
    width: '40px',
    height: '40px',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
        width: '24px',
        height: '24px',
    },
}));

type ButtonAreaProps = {
    visible: boolean;
};

const ButtonArea = styled('div')<ButtonAreaProps>(({ theme, visible }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    visibility: visible ? 'visible' : 'hidden',
    [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(0.5),
    },
}));

export const ProjectInfo: FC<Props> = ({ project, showButtons = false, onEdit, onArchive, onRestore, disabled }) => {
    const isMobile = useMobileLayout();

    const onEditClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();

            if (onEdit) {
                onEdit();
            }
        },
        [onEdit]
    );

    const onArchiveClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();

            if (onArchive) {
                onArchive();
            }
        },
        [onArchive]
    );

    const onRestoreClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();

            if (onRestore) {
                onRestore();
            }
        },
        [onRestore]
    );

    return (
        <Root>
            <Header>
                <ProjectFields>
                    <FieldsBlock>
                        {project.hasLogo && <Logo src={getProjectLogoUrl(project)} />}
                        <Typography variant={isMobile ? 'body1' : 'h5'}>{project.name}</Typography>
                    </FieldsBlock>
                    <DateInterval dateFrom={project.from} dateTo={project.to} />
                </ProjectFields>
                <ButtonArea visible={showButtons}>
                    <IconButton onClick={onEditClick} disabled={disabled || project.isArchived}>
                        <Edit />
                    </IconButton>
                    {project.isArchived ? (
                        <IconButton onClick={onRestoreClick} disabled={disabled}>
                            <Restore />
                        </IconButton>
                    ) : (
                        <IconButton onClick={onArchiveClick} disabled={disabled}>
                            <Archive />
                        </IconButton>
                    )}
                </ButtonArea>
            </Header>
        </Root>
    );
};
