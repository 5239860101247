import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { DEFAULT_DATE, JsonDate } from './utils';

@JsonObject()
export class WorkloadInput {
    @JsonProperty()
    id?: string;

    @JsonProperty()
    employeeId: string = '';

    @JsonProperty()
    projectId: string = '';

    @JsonDate()
    from: Date = DEFAULT_DATE;

    @JsonDate()
    to: Date = DEFAULT_DATE;

    @JsonProperty()
    percent: number = 0;

    @JsonProperty()
    comment?: string;
}

export const getWorkloadInput = (input: WorkloadInput) => Object.assign(new WorkloadInput(), input);
