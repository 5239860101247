import { action, makeObservable, observable } from 'mobx';
import { EmployeeDetails } from '../dto/EmployeeDetailsDto';
import { employeeDetailsRequest } from '../views/EmployeeDetailsPage';

class EmployeeDetailsStore {
    constructor() {
        makeObservable(this);
    }

    @observable
    data?: EmployeeDetails;

    @observable
    isLoading = false;

    @observable
    error: Error | null = null;

    @action refresh = (id: string) => {
        this.isLoading = true;
        this.error = null;

        employeeDetailsRequest
            .request({ id })
            .then(result => (this.data = result))
            .catch(error => (this.error = error))
            .finally(() => {
                this.isLoading = false;
            });
    };
}

export const employeeDetailsStore = new EmployeeDetailsStore();
