import React, { FC } from 'react';
import { Box, Link, styled, Typography } from '@mui/material';

const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}));

export const RegisterCompleteInfo: FC = () => {
    return (
        <Root>
            <Typography>
                Регистрация завершена. Проверьте почту (в том числе папку "Спам") и следуйте инструкциям из письма.
            </Typography>
            <Link href="/">
                <Typography>На главную</Typography>
            </Link>
        </Root>
    );
};
