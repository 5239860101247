import { JsonObject, JsonProperty } from 'typescript-json-serializer';

@JsonObject()
export class RegisterInput {
    @JsonProperty()
    email: string = '';

    @JsonProperty()
    password: string = '';

    @JsonProperty()
    confirmPassword: string = '';
}

export const getRegisterInput = (input: RegisterInput): RegisterInput => Object.assign(new RegisterInput(), input);
