import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { DEFAULT_DATE, JsonDate } from './utils';

@JsonObject()
export class Availability {
    @JsonDate()
    from: Date = DEFAULT_DATE;

    @JsonDate()
    to?: Date;

    @JsonProperty()
    percent?: number;
}

@JsonObject()
export class Employee {
    @JsonProperty()
    id: string = '';

    @JsonProperty()
    name: string = '';

    @JsonProperty()
    surname: string = '';

    @JsonProperty()
    patronymic?: string;

    @JsonProperty()
    hasPhoto: boolean = false;

    @JsonProperty()
    isArchived: boolean = false;

    @JsonProperty({ type: Availability })
    availability: Availability[] = [];

    @JsonProperty({ type: Availability })
    overload: Availability[] = [];

    @JsonProperty({ type: Availability })
    vacation: Availability[] = [];
}
