import React from 'react';
import { IRequest } from '../api/types';

export const useRequest = <Input, Output>(request: IRequest<Input, Output>, input: Input) => {
    const [data, setData] = React.useState<Output | null>(null);
    const [error, setError] = React.useState<Error | null>(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (!request.isLoading) {
            request
                .request(input)
                .then(res => setData(res))
                .catch(err => setError(err))
                .finally(() => setLoading(false));
        }
    }, [JSON.stringify(input), request]);

    return { data, error, loading };
};
