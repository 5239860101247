import { Stack } from '@mui/material';
import { observer } from 'mobx-react';
import { MessageItem } from './MessageItem';
import { messageStore } from '../../stores/messageStore';

export const MessageProvider = observer(() => {
    const messages = messageStore.messages.slice();

    return (
        <Stack>
            {messages.map((message, index) => (
                <MessageItem message={message} key={message.key} index={index} />
            ))}
        </Stack>
    );
});
