import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, styled, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { getFormattedWorkloadValue, getIntervalStartForDate, WorkloadChartRow } from '../../utils/workload';
import { TodayOutlined } from '@mui/icons-material';
import {
    generateColumns,
    getGroupColumns,
    getGroupRows,
    getPartialPercent,
    getPercentColor,
    getTotalWorkload,
} from './WorkloadChart.helpers';
import { WorkloadChartTooltip } from './WorkloadChartTooltip';
import { NoDataPlaceholder } from '../NoDataPlaceholder';
import { Interval } from '../../utils/types';
import { planningModeStore } from '../../stores/planningModeStore';
import { observer } from 'mobx-react';

const maxColumnWidth = 40;

const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}));

const Header = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const ChartTable = styled('table')({
    tableLayout: 'fixed',
    width: '100%',
    overflow: 'auto',
    display: 'block',
    borderSpacing: '0',
    maxHeight: 'calc(50vh - 140px)',
    overflowY: 'auto',
});

type CellProps = {
    filled: boolean;
    percent: number;
    highlight?: boolean;
    selected?: boolean;
    canClick?: boolean;
};

const ChartCell = styled('td')<CellProps>(
    ({ filled, percent, highlight = false, selected = false, canClick = false }) => ({
        maxWidth: `${maxColumnWidth}px`,
        minWidth: `${maxColumnWidth}px`,
        minHeight: `${maxColumnWidth}px`,
        maxHeight: `${maxColumnWidth}px`,
        border: `1px solid ${selected ? '#444444' : '#BBBBBB'}`,
        background: filled ? getPercentColor(percent) : highlight ? 'aliceblue' : 'inherit',
        paddingTop: '0px',
        paddingBottom: '0px',
        cursor: canClick ? 'pointer' : 'inherit',
    })
);

const FirstColumnHeaderCell = styled('th')(({ theme }) => ({
    position: 'sticky',
    left: 0,
    background: '#FFFFFF',
    padding: theme.spacing(1),
    textAlign: 'left',
    borderBottom: `1px solid #BBBBBB`,
}));

type FirstColumnProps = {
    group: boolean;
};

const FirstColumnCell = styled('td')<FirstColumnProps>(({ theme, group }) => ({
    position: 'sticky',
    left: 0,
    background: '#FFFFFF',
    padding: theme.spacing(1),
    textWrap: 'nowrap',
    paddingLeft: group ? 'inherit' : theme.spacing(2),
    maxWidth: '150px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: '0px',
    paddingBottom: '0px',
}));

const TableHeader = styled('thead')({
    position: 'sticky',
    top: 0,
    background: '#FFFFFF',
    zIndex: 2,
});

type HeaderCellProps = {
    highlight?: boolean;
};

const HeaderCell = styled('th')<HeaderCellProps>(({ highlight = false }) => ({
    maxWidth: `${maxColumnWidth}px`,
    minWidth: `${maxColumnWidth}px`,
    minHeight: `${maxColumnWidth}px`,
    maxHeight: `${maxColumnWidth}px`,
    borderColor: '#BBBBBB',
    borderWidth: '1px',
    borderStyle: 'solid',
    paddingLeft: '2px',
    paddingRight: '2px',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
    boxSizing: 'border-box',
    background: highlight ? 'lightblue' : 'inherit',
}));

const HeaderWorkloadCell = styled('th')<CellProps>(({ filled, percent }) => ({
    maxWidth: `${maxColumnWidth}px`,
    minWidth: `${maxColumnWidth}px`,
    minHeight: `${maxColumnWidth}px`,
    maxHeight: `${maxColumnWidth}px`,
    border: '1px solid #BBBBBB',
    boxSizing: 'border-box',
    background: filled ? getPercentColor(percent) : 'inherit',
}));

const getCellId = (row: { id: string }, column: { key: string }) => `${row.id}_${column.key}`;

const isRowMatchCellID = (row: { id: string }, cellId: string) => {
    const reg = new RegExp(`^${row.id}_[\\S\\s]+$`, 'g');

    return reg.test(cellId);
};

type Props = {
    data: WorkloadChartRow[];
    showTotalWorkload: boolean;
    onCellClick: (id: string) => void;
    selectedId?: string;
    disabled?: boolean;
};

export const WorkloadChart: FC<Props> = observer(
    ({ data, showTotalWorkload, onCellClick, selectedId, disabled = false }) => {
        const [interval, setInterval] = useState<Interval>('week');

        const [selectedCellId, setSelectedCellId] = useState<string | undefined>(undefined);

        const columns = useMemo(() => generateColumns(data, interval, 5), [interval, data]);

        const groupColumns = useMemo(() => getGroupColumns(columns), [columns]);

        const todayKey = useMemo(() => {
            const now = new Date();
            now.setHours(0, 0, 0, 0);

            return getIntervalStartForDate(now, interval).toISOString();
        }, [interval]);

        const colRefs = useRef(new Map<string, HTMLTableCellElement>());
        const cellRefs = useRef(new Map<string, HTMLTableCellElement>());

        const scrollToToday = useCallback(() => {
            const element = colRefs.current.get(todayKey);
            if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }, [todayKey, colRefs]);

        useEffect(() => {
            scrollToToday();
        }, [scrollToToday]);

        const saveColRef = (id: string, element: HTMLTableCellElement | null) => {
            if (element) {
                colRefs.current.set(id, element);
            }
        };

        const saveCellRef = (id: string, element: HTMLTableCellElement | null) => {
            if (element) {
                cellRefs.current.set(id, element);
            }
        };

        const onIntervalChange = (_event: React.MouseEvent<HTMLElement>, newValue: Interval) => {
            setInterval(newValue);
        };

        const mode = planningModeStore.mode;

        const groupedData = useMemo(() => getGroupRows(columns, data, mode), [columns, data, mode]);

        const totalData = useMemo(
            () => (showTotalWorkload ? getTotalWorkload(columns, groupedData, 'Общая') : null),
            [columns, groupedData, showTotalWorkload]
        );

        useEffect(() => {
            if (!selectedId) return;
            const row = data.find(item => item.id === selectedId);
            if (!row || isRowMatchCellID(row, selectedCellId || '')) return;
            const column = columns.find(
                c => c.from.getTime() <= row.from.getTime() && c.to.getTime() >= row.from.getTime()
            );
            if (!column) return;

            const cellId = getCellId(row, column);
            setSelectedCellId(cellId);

            const element = cellRefs.current.get(cellId);
            if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }, [selectedId, data, columns, selectedCellId]);

        if (data.length === 0) return <NoDataPlaceholder />;

        return (
            <Root>
                <Header>
                    <ToggleButtonGroup
                        value={interval}
                        onChange={onIntervalChange}
                        color="primary"
                        exclusive
                        disabled={disabled}
                    >
                        <ToggleButton value={'day'} disabled={interval === 'day'}>
                            День
                        </ToggleButton>
                        <ToggleButton value={'week'} disabled={interval === 'week'}>
                            Неделя
                        </ToggleButton>
                        <ToggleButton value={'month'} disabled={interval === 'month'}>
                            Месяц
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Button onClick={scrollToToday} variant="outlined" endIcon={<TodayOutlined />} disabled={disabled}>
                        Сегодня
                    </Button>
                </Header>
                <ChartTable>
                    <TableHeader>
                        {interval !== 'month' && (
                            <tr>
                                <FirstColumnHeaderCell rowSpan={!!totalData ? 3 : 2}>
                                    <Typography variant="caption" fontWeight={600}>
                                        Дата
                                    </Typography>
                                </FirstColumnHeaderCell>
                                {groupColumns.map(r => (
                                    <HeaderCell key={r.id} colSpan={r.spanCount}>
                                        <Typography variant="caption">{r.label}</Typography>
                                    </HeaderCell>
                                ))}
                            </tr>
                        )}
                        <tr>
                            {interval === 'month' && (
                                <FirstColumnHeaderCell rowSpan={!!totalData ? 2 : 1}>
                                    <Typography variant="caption" fontWeight={600}>
                                        Дата
                                    </Typography>
                                </FirstColumnHeaderCell>
                            )}
                            {columns.map(r => (
                                <HeaderCell
                                    key={r.key}
                                    ref={el => saveColRef(r.key, el)}
                                    highlight={r.key === todayKey}
                                >
                                    <Typography variant="caption">{r.label}</Typography>
                                </HeaderCell>
                            ))}
                        </tr>
                        {totalData && (
                            <tr>
                                {columns.map(r => (
                                    <Tooltip
                                        key={'T' + r.key}
                                        title={
                                            totalData.itemsMap.has(r.key) ? (
                                                <WorkloadChartTooltip info={totalData.itemsMap.get(r.key)!} />
                                            ) : null
                                        }
                                    >
                                        <HeaderWorkloadCell
                                            filled={totalData.itemsMap.has(r.key)}
                                            percent={totalData.itemsMap.get(r.key)?.totalPercent || 0}
                                        >
                                            &nbsp;
                                        </HeaderWorkloadCell>
                                    </Tooltip>
                                ))}
                            </tr>
                        )}
                    </TableHeader>
                    <tbody>
                        {groupedData.map((groupRow, ind) => (
                            <React.Fragment key={'G' + groupRow.section.id}>
                                <tr>
                                    <FirstColumnCell group={true}>
                                        <Tooltip title={groupRow.section.label}>
                                            <Typography variant="caption" fontWeight={600}>
                                                {groupRow.section.label}
                                            </Typography>
                                        </Tooltip>
                                    </FirstColumnCell>
                                    {columns.map(r => (
                                        <Tooltip
                                            key={'G' + getCellId(groupRow.section, r)}
                                            title={
                                                groupRow.itemsMap.has(r.key) ? (
                                                    <WorkloadChartTooltip info={groupRow.itemsMap.get(r.key)!} />
                                                ) : null
                                            }
                                        >
                                            <ChartCell
                                                filled={groupRow.itemsMap.has(r.key)}
                                                percent={groupRow.itemsMap.get(r.key)?.totalPercent || 0}
                                                highlight={ind % 2 === 1}
                                            />
                                        </Tooltip>
                                    ))}
                                </tr>
                                {data
                                    .filter(item => item.section.id === groupRow.section.id)
                                    .map(row => {
                                        return (
                                            <tr key={row.id}>
                                                <FirstColumnCell group={false}>
                                                    <Typography variant="caption">
                                                        {row.comment || 'Без комментария'}
                                                    </Typography>
                                                </FirstColumnCell>
                                                {columns.map(r => {
                                                    const partialPercent = getPartialPercent(row, r);

                                                    return (
                                                        <Tooltip
                                                            title={
                                                                partialPercent !== null
                                                                    ? getFormattedWorkloadValue(
                                                                          {
                                                                              from: r.from,
                                                                              to: r.to,
                                                                              percent: partialPercent,
                                                                          },
                                                                          planningModeStore.mode
                                                                      )
                                                                    : null
                                                            }
                                                            key={getCellId(row, r)}
                                                        >
                                                            <ChartCell
                                                                filled={partialPercent !== null}
                                                                percent={partialPercent || 0}
                                                                highlight={ind % 2 === 1}
                                                                selected={getCellId(row, r) === selectedCellId}
                                                                onClick={
                                                                    partialPercent !== null && !disabled
                                                                        ? () => {
                                                                              setSelectedCellId(getCellId(row, r));
                                                                              onCellClick(row.id);
                                                                          }
                                                                        : undefined
                                                                }
                                                                canClick={partialPercent !== null && !disabled}
                                                                ref={el => saveCellRef(getCellId(row, r), el)}
                                                            />
                                                        </Tooltip>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                            </React.Fragment>
                        ))}
                    </tbody>
                </ChartTable>
            </Root>
        );
    }
);
