import { Box, Paper, styled, Tab, Tabs } from '@mui/material';
import React, { FC, ReactNode, useState } from 'react';
import { AdminUsers } from './AdminUsers';

const Root = styled(Paper)(({ theme }) => ({
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',

    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5),
    },
}));

type Props = {
    value: number;
    index: number;
    children: ReactNode;
};

const AdminTab: FC<Props> = ({ value, index, children }) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && children}
        </div>
    );
};

const Container = styled(Box)(({ theme }) => ({
    height: 'calc(100vh - 176px)',

    [theme.breakpoints.down('sm')]: {
        height: 'calc(100vh - 144px)',
    },
}));

export const AdminTabs: FC = () => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <Root>
            <Tabs value={activeTab} onChange={(_e, newValue) => setActiveTab(newValue)}>
                <Tab label="Пользователи" />
            </Tabs>
            <AdminTab value={activeTab} index={0}>
                <Container>
                    <AdminUsers />
                </Container>
            </AdminTab>
        </Root>
    );
};
