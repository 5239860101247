import { createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#136207',
        },
        secondary: {
            main: '#9AB5C6',
        },
        text: {
            secondary: '#888888',
        },
        // background: {
        //     default: '#F0F4F8',
        // },
    },
    // typography: {
    //     h1: {
    //         fontSize: '2rem',
    //     },
    // },
    components: {
        // MuiOutlinedInput: {
        //     styleOverrides: {
        //         root: {
        //             borderRadius: 10,
        //             backgroundColor: '#fff',
        //         },
        //     },
        // },
        // MuiInputLabel: {
        //     styleOverrides: {
        //         root: {
        //             color: '#9AB5C6',
        //             fontWeight: 500,
        //             fontSize: 12,
        //         },
        //     },
        // },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                // endIcon: {
                //     position: 'absolute',
                //     right: 32,
                // },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        // MuiLink: {
        //     styleOverrides: {
        //         root: {
        //             color: '#9AB5C6',
        //             textDecorationColor: 'inherit',
        //             display: 'flex',
        //             gap: 12,
        //         },
        //     },
        // },
    },
});
