import React, { FC } from 'react';
import { Layout } from '../../components/Layout';
import { Box, styled } from '@mui/material';
import { AvailabilityWidget } from './AvailabilityWidget';
import { OverloadWidget } from './OverloadWidget';
import { VacationWidget } from './VacationWidget';

const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: theme.spacing(1),
        height: 'calc(100vh - 78px)',
        overflowY: 'auto',
    },
}));

const Column = styled(Box)({
    flex: 1,
});

const ScrollableCell = styled(Box)(({ theme }) => ({
    overflowY: 'auto',
    height: 'calc(100vh - 96px)',

    [theme.breakpoints.down('sm')]: {
        height: '100%',
    },
}));

const ScrollableSmallCell = styled(Box)(({ theme }) => ({
    overflowY: 'auto',
    height: 'calc(50vh - 56px)',

    [theme.breakpoints.down('sm')]: {
        height: '100%',
    },
}));

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
    },
}));

export const HomePage: FC = () => {
    return (
        <Layout title="Планирование ресурсов" showSelector={false}>
            <Root>
                <Column>
                    <ScrollableCell>
                        <AvailabilityWidget />
                    </ScrollableCell>
                </Column>
                <Column>
                    <Container>
                        <ScrollableSmallCell>
                            <OverloadWidget />
                        </ScrollableSmallCell>
                        <ScrollableSmallCell>
                            <VacationWidget />
                        </ScrollableSmallCell>
                    </Container>
                </Column>
            </Root>
        </Layout>
    );
};
