import { mockApi } from '../../../api/constants';
import { FakeRequest } from '../../../api/FakeRequest';
import { HttpRequest } from '../../../api/HttpRequest';
import { EmployeeAvailability } from '../../../dto';
import { serializer } from '../../../dto/utils';

export const employeesOverloadRequest = !mockApi
    ? new HttpRequest<{ percent: number }, EmployeeAvailability[]>({
          method: 'post',
          path: () => `/employee/overload`,
          mapper: data => serializer.deserializeObjectArray(data, EmployeeAvailability) as EmployeeAvailability[],
          body: body => serializer.serializeObject(body) || {},
      })
    : new FakeRequest<{ percent: number }, EmployeeAvailability[]>({
          fakeData: () =>
              serializer.deserializeObjectArray(
                  [
                      {
                          id: '01',
                          name: 'Jane',
                          surname: 'Doe',
                          hasPhoto: false,
                          availability: [
                              {
                                  from: '01.01.2024',
                                  to: '31.10.2024',
                                  percent: 0.8,
                              },
                              {
                                  from: '01.01.2024',
                                  to: '31.07.2024',
                                  percent: 0.9,
                              },
                          ],
                      },
                  ],
                  EmployeeAvailability
              ) as EmployeeAvailability[],
      });
