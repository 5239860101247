import { JsonObject, JsonProperty } from 'typescript-json-serializer';

@JsonObject()
export class LoginInput {
    @JsonProperty()
    email: string = '';

    @JsonProperty()
    password: string = '';
}

export const getLoginInput = (input: LoginInput): LoginInput => Object.assign(new LoginInput(), input);
