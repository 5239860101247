import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { FileInput } from './FileInputDto';

@JsonObject()
export class EmployeeInput {
    @JsonProperty()
    id?: string;

    @JsonProperty()
    name: string = '';

    @JsonProperty()
    surname: string = '';

    @JsonProperty()
    patronymic?: string;

    @JsonProperty({ type: FileInput })
    photo?: FileInput;
}

export const getEmployeeInput = (input: EmployeeInput) => Object.assign(new EmployeeInput(), input);
