import { FakeRequest } from '../../api/FakeRequest';
import { Project } from '../../dto';
import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';
import { serializer } from '../../dto/utils';

export const projectsRequest = !mockApi
    ? new HttpRequest<{ includeArchive: boolean }, Project[]>({
          method: 'get',
          path: input =>
              `/project?${new URLSearchParams({
                  includeArchive: input.includeArchive.toString(),
              })}`,
          mapper: data => serializer.deserializeObjectArray(data, Project) as Project[],
      })
    : new FakeRequest<{ includeArchive: boolean }, Project[]>({
          fakeData: () =>
              serializer.deserializeObjectArray(
                  [
                      {
                          id: '1',
                          name: 'Project 1',
                          from: '01.01.2024',
                          to: '31.10.2024',
                          hasLogo: false,
                          participants: [
                              {
                                  id: '11',
                                  name: 'Jane',
                                  surname: 'Doe',
                                  hasPhoto: false,
                              },
                              {
                                  id: '12',
                                  name: 'John',
                                  surname: 'Doe',
                                  hasPhoto: false,
                              },
                          ],
                      },
                      {
                          id: '2',
                          name: 'Project 2',
                          from: '01.03.2024',
                          to: '31.12.2024',
                          hasLogo: false,
                          participants: [
                              {
                                  id: '21',
                                  name: 'Peter',
                                  surname: 'Parker',
                                  hasPhoto: false,
                              },
                          ],
                      },
                  ],
                  Project
              ) as Project[],
      });
