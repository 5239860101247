import React, { FC } from 'react';
import { Layout } from '../../components/Layout';
import { AdminTabs } from './AdminTabs';

export const AdminPage: FC = () => {
    return (
        <Layout title="Администрирование" showSelector={false}>
            <AdminTabs />
        </Layout>
    );
};
