import { Box, styled, Typography } from '@mui/material';
import React, { FC } from 'react';

const Root = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: '100%',
});

export const NoDataPlaceholder: FC = () => (
    <Root>
        <Typography>Нет данных для отображения</Typography>
    </Root>
);
