import React, { FC } from 'react';
import { UserStatus } from '../../dto';
import { ColorChip } from '../ColorChip';

type Props = {
    status: UserStatus;
    size?: 'small' | 'medium';
};

export const UserStatusBadge: FC<Props> = ({ status, size = 'medium' }) => {
    if (status === UserStatus.NOT_VERIFIED)
        return <ColorChip custombackcolor="cornsilk" label="Не подтвержден" size={size} />;
    if (status === UserStatus.NOT_APPROVED)
        return <ColorChip custombackcolor="lavender" label="Ожидает верификации" size={size} />;

    return <ColorChip custombackcolor="palegreen" label="Активен" size={size} />;
};
