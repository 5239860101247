import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';
import { FakeRequest } from '../../api/FakeRequest';

export const approveUserRequest = !mockApi
    ? new HttpRequest<{ id: string }, void>({
          method: 'post',
          path: params => `/user/${params.id}/approve`,
          mapper: data => data,
      })
    : new FakeRequest<{ id: string }, void>({
          fakeData: () => {},
      });
