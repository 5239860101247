import { Box, LinearProgress, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useRequest } from '../../../hooks';
import { OverloadCard } from './OverloadCard';
import { squashAvailability } from '../../../utils/availability';
import { employeesOverloadRequest } from './employeesOverloadRequest';
import { NoDataPlaceholder } from '../../../components/NoDataPlaceholder';

const Root = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
}));

const Container = styled(Box)({
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
});

export const OverloadWidget: FC = () => {
    const { data, error, loading } = useRequest(employeesOverloadRequest, { percent: 1.001 });

    const items = useMemo(() => {
        if (!data) return [];

        const availableItems = data
            .filter(item => item.availability.length > 0)
            .map(item => ({
                ...item,
                availability: squashAvailability(item.availability),
            }));

        availableItems.sort((a, b) => +a.availability[0].from - +b.availability[0].from);

        return availableItems;
    }, [data]);

    return (
        <Root>
            <Typography variant="h6">Превышение загрузки</Typography>
            {loading && <LinearProgress />}
            {error && <Typography color="error">{error.message}</Typography>}
            {!loading && items.length === 0 && <NoDataPlaceholder />}
            <Container>
                {items.map(item => (
                    <OverloadCard item={item} key={item.id} />
                ))}
            </Container>
        </Root>
    );
};
