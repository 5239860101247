import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { Box, IconButton, styled, TableCell, Typography } from '@mui/material';
import { formatDate } from '../../utils/formatters';
import { Delete, Edit } from '@mui/icons-material';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { DisplayEmployee, DisplayProject } from '../../utils/types';
import { Workload } from '../../dto/WorkloadDto';
import { WorkloadForm } from '../../views/common/WorkloadForm';
import { WorkloadInput } from '../../dto';
import { getFormattedWorkloadValue } from '../../utils/workload';
import { planningModeStore } from '../../stores/planningModeStore';
import { observer } from 'mobx-react';
import { useMobileLayout } from '../../hooks';
import { Variant } from '@mui/material/styles/createTypography';

const ButtonArea = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    gap: theme.spacing(0.5),
}));

type Props = {
    renderSection: () => ReactNode;
    project: DisplayProject;
    employee: DisplayEmployee;
    workload: Workload;
    isActive: boolean;
    onActivate: () => void;
    onDeactivate: () => void;
    onRemove: () => void;
    onCreate: (input: WorkloadInput) => Promise<void>;
    onUpdate: (input: WorkloadInput) => Promise<void>;
    options: {
        allowProjectEdit?: boolean;
        allowEmployeeEdit?: boolean;
    };
    createRef: (el: HTMLTableRowElement | null) => void;
    isSelected?: boolean;
    onClick: () => void;
    disabled?: boolean;
};

type RowProps = {
    isSelected?: boolean;
    disabled?: boolean;
};

const Row = styled('tr')<RowProps>(({ theme, isSelected = false, disabled = false }) => ({
    padding: theme.spacing(1),
    background: isSelected ? 'aliceblue' : 'inherit',
    cursor: disabled ? 'inherit' : 'pointer',
}));

const Cell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(1),
}));

export const WorkloadRow: FC<Props> = observer(
    ({
        renderSection,
        project,
        employee,
        workload,
        isActive,
        onActivate,
        onDeactivate,
        onRemove,
        onCreate,
        onUpdate,
        options,
        createRef,
        isSelected,
        onClick,
        disabled = false,
    }) => {
        const [open, setOpen] = useState<boolean>(false);
        const [removeOpen, setRemoveOpen] = useState<boolean>(false);

        const isMobile = useMobileLayout();

        const variant: Variant = useMemo(() => (isMobile ? 'caption' : 'body2'), [isMobile]);

        const onRemoveAnswer = useCallback(
            async (answer: boolean) => {
                setRemoveOpen(false);

                if (!answer) return;

                return onRemove();
            },
            [onRemove]
        );

        return (
            <>
                <Row
                    onMouseEnter={onActivate}
                    onMouseLeave={onDeactivate}
                    ref={createRef}
                    isSelected={isSelected}
                    onClick={disabled ? undefined : onClick}
                    disabled={disabled}
                >
                    <Cell align="left">
                        <Typography variant={variant}>{renderSection()}</Typography>
                    </Cell>
                    <Cell>
                        <Typography variant={variant}>{formatDate(workload.from)}</Typography>
                    </Cell>
                    <Cell>
                        <Typography variant={variant}>{formatDate(workload.to)}</Typography>
                    </Cell>
                    <Cell>
                        <Typography variant={variant}>
                            {getFormattedWorkloadValue(workload, planningModeStore.mode)}
                        </Typography>
                    </Cell>
                    <Cell>
                        <Typography variant={variant}>{workload.comment}</Typography>
                    </Cell>
                    <Cell align="right">
                        <ButtonArea style={{ visibility: isActive || isMobile ? undefined : 'hidden' }}>
                            <IconButton onClick={() => setOpen(true)} disabled={disabled}>
                                <Edit />
                            </IconButton>
                            <IconButton onClick={() => setRemoveOpen(true)} disabled={disabled}>
                                <Delete />
                            </IconButton>
                        </ButtonArea>
                    </Cell>
                </Row>
                <WorkloadForm
                    project={project}
                    employee={employee}
                    open={open}
                    onClose={() => setOpen(false)}
                    onCreate={onCreate}
                    onUpdate={onUpdate}
                    workload={workload}
                    options={options}
                />
                <ConfirmationDialog
                    title="Удалить?"
                    message="Действительно удалить загрузку?"
                    open={removeOpen}
                    onClose={onRemoveAnswer}
                    icon={<Delete />}
                />
            </>
        );
    }
);
