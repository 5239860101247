import { getRegisterInput, RegisterInput } from '../../dto';
import { FormikConfig } from 'formik';
import * as yup from 'yup';
import { isEmailValid, MIN_PASSWORD_LENGTH } from '../../utils/user';

export type IFormValues = {
    email: string;
    password: string;
    confirmPassword: string;
};

export const getRegisterFormik = (
    onRegister: (input: RegisterInput) => Promise<unknown>
): FormikConfig<IFormValues> => {
    return {
        initialValues: {
            email: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: yup.object().shape({
            email: yup
                .string()
                .trim()
                .required('Укажите email')
                .test({
                    name: 'Email should be valid',
                    test: function (value, { createError }) {
                        if (!isEmailValid(value))
                            return createError({
                                path: 'email',
                                message: 'Некорректный email',
                            });

                        return true;
                    },
                }),
            password: yup
                .string()
                .trim()
                .required('Укажите пароль')
                .min(MIN_PASSWORD_LENGTH, 'Слишком короткий пароль'),
            confirmPassword: yup
                .string()
                .trim()
                .required('Укажите подтверждение пароля')
                .test({
                    name: 'Passwords should match',
                    test: function (value, { createError, parent }) {
                        if (value !== parent.password) {
                            return createError({
                                path: 'confirmPassword',
                                message: 'Пароли не совпадают',
                            });
                        }

                        return true;
                    },
                }),
        }),
        onSubmit: async (values: IFormValues, formikHelpers) => {
            const { email, password, confirmPassword } = values;

            if (!email || !password || !confirmPassword) {
                return;
            }

            return onRegister(getRegisterInput({ email, password, confirmPassword })).then(() =>
                formikHelpers.resetForm()
            );
        },
    };
};
