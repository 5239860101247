import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { DEFAULT_DATE, JsonDate } from './utils';
import { FileInput } from './FileInputDto';

@JsonObject()
export class ProjectInput {
    @JsonProperty()
    id?: string;

    @JsonProperty()
    name: string = '';

    @JsonDate()
    from: Date = DEFAULT_DATE;

    @JsonDate()
    to: Date = DEFAULT_DATE;

    @JsonProperty({ type: FileInput })
    logo?: FileInput;

    @JsonProperty()
    isVacation: boolean = false;
}

export const getProjectInput = (input: ProjectInput) => Object.assign(new ProjectInput(), input);
