import React, { FC, ReactNode, useState } from 'react';
import { AppBar, Box, Drawer, IconButton, styled, Toolbar, Typography } from '@mui/material';
import { Menu, MenuOpen } from '@mui/icons-material';
import { DrawerItems } from './DrawerItems';
import { PlanningModeSwitch } from './PlanningModeSwitch';

type Props = {
    title: string;
    actions?: 'full' | 'logout' | 'none';
    showSelector?: boolean;
    children: ReactNode;
};

const Root = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    alignItems: 'center',
    background: '#DDDDDD',
}));

const Container = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '80%',

    [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: '95%',
    },
}));

const AppBarItems = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
});

const AppBarLeft = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
}));

const ItemsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
});

export const Layout: FC<Props> = ({ title, children, actions = 'full', showSelector = true }) => {
    const [open, setOpen] = useState(false);

    return (
        <Root>
            <AppBar position="sticky">
                <Toolbar>
                    <AppBarItems>
                        <AppBarLeft>
                            <IconButton color="inherit" onClick={() => setOpen(true)}>
                                {open ? <MenuOpen /> : <Menu />}
                            </IconButton>
                            <Typography variant="subtitle1">{title}</Typography>
                        </AppBarLeft>
                        {showSelector && <PlanningModeSwitch />}
                    </AppBarItems>
                </Toolbar>
            </AppBar>
            <Drawer open={open} onClose={() => setOpen(false)}>
                <ItemsContainer>
                    <DrawerItems onClose={() => setOpen(false)} actions={actions} />
                    <Typography color="textDisabled">Тут будет пользователь</Typography>
                </ItemsContainer>
            </Drawer>
            <Container>{children}</Container>
        </Root>
    );
};
