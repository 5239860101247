import React, { FC } from 'react';
import { Layout } from '../../components/Layout';
import { Projects } from './Projects';

export const ProjectsPage: FC = () => {
    return (
        <Layout title="Проекты">
            <Projects />
        </Layout>
    );
};
