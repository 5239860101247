import { FC } from 'react';
import { Layout } from '../../components/Layout';
import { Paper, styled, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { authStore } from '../../stores/authStore';
import { UserStatus } from '../../dto';

const Root = styled(Paper)(({ theme }) => ({
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5),
    },
}));

export const NotActivePage: FC = observer(() => (
    <Layout
        title={
            authStore.user?.isBlocked
                ? 'Доступ закрыт'
                : authStore.user?.status === UserStatus.NOT_VERIFIED
                  ? 'Подтвердите email'
                  : 'Верификация'
        }
        actions="logout"
        showSelector={false}
    >
        <Root>
            <Typography>
                {authStore.user?.isBlocked
                    ? 'Пользователь заблокирован, продолжение работы невозможно'
                    : authStore.user?.status === UserStatus.NOT_VERIFIED
                      ? 'Пройдите по ссылке активации из письма'
                      : 'Дождитесь верификации, чтобы начать работу'}
            </Typography>
        </Root>
    </Layout>
));
