import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';
import { LoginInput } from '../../dto';
import { serializer } from '../../dto/utils';
import { FakeRequest } from '../../api/FakeRequest';

export const loginRequest = !mockApi
    ? new HttpRequest<LoginInput, string>({
          method: 'post',
          path: () => '/user/login',
          mapper: data => data,
          body: data => serializer.serializeObject(data) || {},
      })
    : new FakeRequest<LoginInput, string>({
          fakeData: () => 'Some token',
      });
