import React, { FC, useMemo } from 'react';
import { styled, Typography } from '@mui/material';
import { formatDate, formatInterval } from '../../../utils/formatters';
import { DisplayAvailability } from '../../../utils/types';

type Props = {
    slot: DisplayAvailability;
};

const Text = styled(Typography)({
    color: '#777777',
});

export const SlotInfo: FC<Props> = ({ slot }) => {
    const now = useMemo(() => {
        const now = new Date();
        now.setHours(0, 0, 0, 0);

        return now;
    }, []);

    return (
        <Text variant="body2">{`С ${slot.from.getTime() === now.getTime() ? 'сегодня' : formatDate(slot.from)}${slot.to ? ` по ${formatDate(slot.to)}, ${formatInterval(slot.from, slot.to)}` : ''}`}</Text>
    );
};
