import { action, makeObservable, observable } from 'mobx';
import type { PlanningMode } from '../utils/types';

const LS_KEY = 'planningMode';

class PlanningModeStore {
    constructor() {
        makeObservable(this);

        const curMode = localStorage.getItem(LS_KEY);
        this.setMode(curMode === 'hours' ? 'hours' : 'percents');
    }

    @observable
    mode: PlanningMode = 'percents';

    @action
    setMode(mode: PlanningMode) {
        this.mode = mode;
        localStorage.setItem(LS_KEY, mode.toString());
    }
}

export const planningModeStore = new PlanningModeStore();
