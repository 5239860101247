import React, { FC, useCallback } from 'react';
import { Box, Button, Paper, Stack, styled } from '@mui/material';
import { Layout } from '../../components/Layout';
import { observer } from 'mobx-react';
import { resetPasswordRequest } from './resetPasswordRequest';
import { messageStore } from '../../stores/messageStore';
import { Form, Formik } from 'formik';
import { getRequestPasswordFormikConfig } from './RequestPasswordFormik';
import { FormikTextField } from '../../components/FormikFields';
import { useNavigate } from 'react-router';

const Root = styled(Paper)(({ theme }) => ({
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5),
    },
}));

const ButtonArea = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
});

const title = 'Сброс пароля';

export const RequestResetPasswordPage: FC = observer(() => {
    const navigate = useNavigate();

    const requestReset = useCallback(
        async (email: string) =>
            resetPasswordRequest
                .request({ email })
                .then(() => messageStore.success('Запрос на смену пароля отправлен'))
                .then(() => navigate('/')),
        [navigate]
    );

    return (
        <Layout title={title} actions={'none'} showSelector={false}>
            <Root>
                <Formik {...getRequestPasswordFormikConfig(requestReset)}>
                    {formik => (
                        <Form>
                            <Stack spacing={2} flexDirection="column">
                                <FormikTextField
                                    name={'email'}
                                    formik={formik}
                                    disabled={formik.isSubmitting}
                                    label="Email"
                                />
                                <ButtonArea>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={formik.isSubmitting || !formik.touched || !formik.isValid}
                                    >
                                        Сбросить
                                    </Button>
                                </ButtonArea>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Root>
        </Layout>
    );
});
