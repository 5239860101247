import { Box, LinearProgress, Paper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { styled } from '@mui/material';
import React, { FC, useMemo, useState } from 'react';
import { useRequest } from '../../../hooks';
import { squashAvailability } from '../../../utils/availability';
import { employeesVacationRequest } from './employeesVacation';
import { VacationCard } from './VacationCard';
import { AvailabilityInterval } from '../widget.types';
import moment from 'moment/moment';
import { NoDataPlaceholder } from '../../../components/NoDataPlaceholder';

const Root = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    overflowY: 'auto',
}));

const Container = styled(Box)({
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
});

export const VacationWidget: FC = () => {
    const { data, error, loading } = useRequest(employeesVacationRequest, undefined);

    const [interval, setInterval] = useState<AvailabilityInterval>('month');

    const items = useMemo(() => {
        if (!data) return [];

        const now = moment(new Date());
        const availableItems = data
            .map(item => ({
                ...item,
                availability: item.availability.filter(
                    a =>
                        moment(a.from).diff(now, interval === 'week' ? 'days' : 'months', true) <=
                        (interval === 'week' ? 7 : interval === 'month' ? 1 : 3)
                ),
            }))
            .filter(item => item.availability.length > 0)
            .map(item => ({
                ...item,
                availability: squashAvailability(item.availability),
            }));

        availableItems.sort((a, b) => +a.availability[0].from - +b.availability[0].from);

        return availableItems;
    }, [data, interval]);

    const onIntervalChange = (_event: React.MouseEvent<HTMLElement>, newValue: AvailabilityInterval) => {
        setInterval(newValue);
    };

    return (
        <Root>
            <Typography variant="h6">Отпуска</Typography>
            <ToggleButtonGroup
                value={interval}
                onChange={onIntervalChange}
                color="primary"
                exclusive
                disabled={loading}
            >
                <ToggleButton value={'week'} disabled={interval === 'week'}>
                    Неделя
                </ToggleButton>
                <ToggleButton value={'month'} disabled={interval === 'month'}>
                    Месяц
                </ToggleButton>
                <ToggleButton value={'quarter'} disabled={interval === 'quarter'}>
                    Квартал
                </ToggleButton>
            </ToggleButtonGroup>
            {loading && <LinearProgress />}
            {error && <Typography color="error">{error.message}</Typography>}
            {!loading && items.length === 0 && <NoDataPlaceholder />}
            <Container>
                {items.map(item => (
                    <VacationCard item={item} key={item.id} />
                ))}
            </Container>
        </Root>
    );
};
