import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { DEFAULT_DATE, JsonDate } from './utils';

@JsonObject()
export class Workload {
    @JsonProperty()
    id: string = '';

    @JsonDate()
    from: Date = DEFAULT_DATE;

    @JsonDate()
    to: Date = DEFAULT_DATE;

    @JsonProperty()
    percent: number = 0;

    @JsonProperty()
    comment?: string;
}
