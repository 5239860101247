import { Employee } from '../../dto';
import React, { FC, useCallback } from 'react';
import { useRequest } from '../../hooks';
import { employeesRequest } from '../../views/EmployeesPage';
import {
    CircularProgress,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { formatFullName } from '../../utils/formatters';

// TODO Allow nulls

type Props = {
    selectedId: string | null;
    onChange: (value: Employee) => void;
    error?: string;
    render?: (employee: Employee) => React.ReactElement;
    disabled?: boolean;
};

export const EmployeeSelect: FC<Props> = ({
    selectedId,
    onChange,
    error: errorMessage,
    render = (employee: Employee) => formatFullName(employee),
    disabled = false,
}) => {
    const { data, error, loading } = useRequest(employeesRequest, { includeArchive: false });

    const onSelect = useCallback(
        (event: SelectChangeEvent) => {
            const employee = (data || []).find(v => v.id === event.target.value);
            if (!employee) return;

            onChange(employee);
        },
        [data, onChange]
    );

    if (error)
        return (
            <Typography color="error" variant="body1">
                {error.message}
            </Typography>
        );
    if (loading) return <CircularProgress />;

    return (
        <FormControl fullWidth>
            <InputLabel id={'select-id'}>Сотрудник</InputLabel>
            <Select
                value={selectedId || undefined}
                onChange={onSelect}
                fullWidth
                labelId="select-id"
                disabled={disabled}
                label="Сотрудник"
            >
                {(data || []).map(item => (
                    <MenuItem value={item.id} key={item.id}>
                        {render(item)}
                    </MenuItem>
                ))}
            </Select>
            {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
        </FormControl>
    );
};
