import { Grid2, Typography } from '@mui/material';
import React, { FC } from 'react';
import { formatHours, formatPercent } from '../../utils/formatters';
import { GroupIntervalInfo } from './WorkloadChart.types';
import { planningModeStore } from '../../stores/planningModeStore';

type Props = {
    info: GroupIntervalInfo;
};

export const WorkloadChartTooltip: FC<Props> = ({ info }) => {
    return (
        <Grid2 container spacing={0}>
            <Grid2 size={9}>
                <Typography variant="caption" fontWeight={600}>
                    Общая загрузка
                </Typography>
            </Grid2>
            <Grid2 size={3} textAlign="right">
                <Typography variant="caption" fontWeight={600}>
                    {planningModeStore.mode === 'percents' ? formatPercent(info.total) : formatHours(info.total)}
                </Typography>
            </Grid2>
            {info.sections.map(section => (
                <React.Fragment key={section.id}>
                    <Grid2 size={9}>
                        <Typography variant="caption">{section.label}</Typography>
                    </Grid2>
                    <Grid2 size={3} textAlign="right">
                        <Typography variant="caption">
                            {' '}
                            {planningModeStore.mode === 'percents'
                                ? formatPercent(section.value)
                                : formatHours(section.value)}
                        </Typography>
                    </Grid2>
                </React.Fragment>
            ))}
        </Grid2>
    );
};
