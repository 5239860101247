import React, { FC, useCallback, useMemo, useState } from 'react';
import { EmployeeDetails as EmployeeDetailsDto } from '../../dto/EmployeeDetailsDto';
import { EmployeeInfo } from '../../components/EmployeeInfo';
import { Box, Button, IconButton, Paper, styled } from '@mui/material';
import { Add, AddCircleOutlined, ArrowBackOutlined } from '@mui/icons-material';
import { WorkloadRowInfo, WorkloadTable } from '../../components/WorkloadTable';
import { DisplayEmployee } from '../../utils/types';
import { messageStore } from '../../stores/messageStore';
import { employeeDetailsStore } from '../../stores/employeeDetailsStore';
import { WorkloadChart } from '../../components/WorkloadChart';
import { removeWorkloadRequest, WorkloadForm } from '../common/WorkloadForm';
import { WorkloadInput } from '../../dto';
import { createWorkloadRequest } from '../common/WorkloadForm/createWorkloadRequest';
import { updateWorkloadRequest } from '../common/WorkloadForm/updateWorkloadRequest';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { useMobileLayout } from '../../hooks';

type Props = {
    data: EmployeeDetailsDto;
};

const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
    },
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
}));

const InfoBlock = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    height: 'calc(50vh - 90px)',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        height: 'calc(50vh - 72px)',
    },
}));

const ProjectInfo: FC<{ row: WorkloadRowInfo }> = ({ row }) => {
    return <>{row.project.name}</>;
};

export const EmployeeDetails: FC<Props> = observer(({ data }) => {
    const [open, setOpen] = useState<boolean>(false);

    const [selectedId, setSelectedId] = useState<string | undefined>(undefined);

    const navigate = useNavigate();

    const isMobile = useMobileLayout();

    const employee: DisplayEmployee = useMemo(
        () => ({
            id: data.id,
            name: data.name,
            surname: data.surname,
            patronymic: data.patronymic,
            hasPhoto: data.hasPhoto,
            isArchived: data.isArchived,
        }),
        [data]
    );

    const workloadChartRows = useMemo(
        () =>
            data.workload.map(w => ({
                id: w.id,
                from: w.from,
                to: w.to,
                section: {
                    id: w.project.id,
                    label: w.project.name,
                },
                percent: w.percent,
                comment: w.comment,
            })),
        [data]
    );

    const workloadRows: WorkloadRowInfo[] = useMemo(
        () =>
            data.workload.map(w => ({
                project: w.project,
                employee,
                workload: w,
            })),
        [data, employee]
    );

    const onRemove = useCallback(
        (row: WorkloadRowInfo) =>
            removeWorkloadRequest
                .request({ id: row.workload.id })
                .then(() => messageStore.success('Загрузка удалена'))
                .then(() => employeeDetailsStore.refresh(row.employee.id)),
        []
    );

    const onCreate = useCallback(
        async (input: WorkloadInput) => {
            return createWorkloadRequest
                .request(input)
                .then(() => messageStore.success('Загрузка создана'))
                .then(() => employeeDetailsStore.refresh(employee.id));
        },
        [employee]
    );

    const onUpdate = useCallback(
        async (input: WorkloadInput) => {
            return updateWorkloadRequest
                .request(input)
                .then(() => messageStore.success('Загрузка обновлена'))
                .then(() => employeeDetailsStore.refresh(employee.id));
        },
        [employee]
    );

    return (
        <Root>
            <InfoBlock>
                <Header>
                    <IconButton onClick={() => navigate('/employee')}>
                        <ArrowBackOutlined />
                    </IconButton>
                    <EmployeeInfo employee={data} clickable={false} />
                    {isMobile ? (
                        <IconButton
                            onClick={() => {
                                setOpen(() => true);
                            }}
                            disabled={employeeDetailsStore.isLoading}
                            size="large"
                            color="primary"
                        >
                            <AddCircleOutlined />
                        </IconButton>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={() => {
                                setOpen(() => true);
                            }}
                            endIcon={<Add />}
                            disabled={employeeDetailsStore.isLoading}
                        >
                            Создать
                        </Button>
                    )}
                </Header>
                <WorkloadTable
                    data={workloadRows}
                    onRemove={onRemove}
                    onCreate={onCreate}
                    onUpdate={onUpdate}
                    renderSection={row => <ProjectInfo row={row} />}
                    sectionHeader="Проект"
                    options={{ allowProjectEdit: false, allowEmployeeEdit: false }}
                    rowId={selectedId}
                    setRowId={setSelectedId}
                    disabled={employeeDetailsStore.isLoading}
                />
            </InfoBlock>
            <InfoBlock>
                <WorkloadChart
                    data={workloadChartRows}
                    showTotalWorkload={true}
                    onCellClick={setSelectedId}
                    selectedId={selectedId}
                    disabled={employeeDetailsStore.isLoading}
                />
            </InfoBlock>
            <WorkloadForm
                employee={employee}
                open={open}
                onClose={() => setOpen(false)}
                onCreate={onCreate}
                onUpdate={onUpdate}
                options={{ allowProjectEdit: true, allowEmployeeEdit: false }}
            />
        </Root>
    );
});
