import React, { FC, useCallback } from 'react';
import { Employee, EmployeeInput } from '../../../dto';
import { messageStore } from '../../../stores/messageStore';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid2,
    LinearProgress,
    styled,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { createEmployeeRequest } from './createEmployeeRequest';
import { employeesStore } from '../../../stores/employeesStore';
import { getEmployeeFormikConfig, IFormValues } from './EmployeeFormik';
import { FormikFileField, FormikTextField } from '../../../components/FormikFields';
import { updateEmployeeRequest } from './updateEmployeeRequest';

type Props = {
    employee?: Employee;
    open: boolean;
    onClose: (id?: string) => void;
};

const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}));

export const EmployeeForm: FC<Props> = ({ employee, open, onClose }) => {
    const onCreate = useCallback(
        async (input: EmployeeInput) => {
            return createEmployeeRequest
                .request(input)
                .then(result => onClose(result.id))
                .then(() => messageStore.success('Сотрудник создан'))
                .then(employeesStore.refresh);
        },
        [onClose]
    );

    const onUpdate = useCallback(
        async (input: EmployeeInput) => {
            return updateEmployeeRequest
                .request(input)
                .then(result => onClose(result.id))
                .then(() => messageStore.success('Сотрудник обновлен'))
                .then(employeesStore.refresh);
        },
        [onClose]
    );

    return (
        <Formik<IFormValues> {...getEmployeeFormikConfig(employee ? onUpdate : onCreate, employee)}>
            {formik => (
                <Dialog open={open} maxWidth="md" fullWidth disableRestoreFocus>
                    <DialogTitle>{employee ? 'Изменить сотрудника' : 'Создать сотрудника'}</DialogTitle>
                    <Form>
                        <DialogContent>
                            <Root>
                                {formik.isSubmitting && <LinearProgress />}
                                <Grid2 container spacing={2}>
                                    <Grid2 size={12}>
                                        <FormikTextField
                                            name={'surname'}
                                            formik={formik}
                                            label="Фамилия"
                                            required
                                            autoFocus
                                            disabled={formik.isSubmitting}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <FormikTextField
                                            name={'name'}
                                            formik={formik}
                                            label="Имя"
                                            required
                                            disabled={formik.isSubmitting}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <FormikTextField
                                            name={'patronymic'}
                                            formik={formik}
                                            label="Отчество"
                                            disabled={formik.isSubmitting}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <FormikFileField
                                            name={'photo'}
                                            extensionName={'photoExtension'}
                                            formik={formik}
                                            hasFile={employee?.hasPhoto}
                                            label="Фотография"
                                            disabled={formik.isSubmitting}
                                        />
                                    </Grid2>
                                </Grid2>
                                <Divider />
                                <DialogActions>
                                    <Button variant="outlined" onClick={() => onClose()} disabled={formik.isSubmitting}>
                                        Отмена
                                    </Button>
                                    <Button variant="contained" type="submit" disabled={formik.isSubmitting}>
                                        Сохранить
                                    </Button>
                                </DialogActions>
                            </Root>
                        </DialogContent>
                    </Form>
                </Dialog>
            )}
        </Formik>
    );
};
