import { FC } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { formatDate } from '../../utils/formatters';
import { ArrowRight } from '@mui/icons-material';
import { useMobileLayout } from '../../hooks';

type Props = {
    dateFrom: Date;
    dateTo: Date;
};

const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(0.5),
}));

export const DateInterval: FC<Props> = ({ dateFrom, dateTo }) => {
    const isMobile = useMobileLayout();

    return (
        <Root>
            <Typography variant={isMobile ? 'caption' : 'body1'} color="textSecondary">
                {formatDate(dateFrom)}
            </Typography>
            <ArrowRight fontSize="small" />
            <Typography variant={isMobile ? 'caption' : 'body1'} color="textSecondary">
                {formatDate(dateTo)}
            </Typography>
        </Root>
    );
};
