import { messageStore } from '../stores/messageStore';
import { ApiError } from '../utils/types';

const errorIsApiError = (error: unknown): error is ApiError => {
    return typeof error === 'object' && !!error && Object.hasOwn(error, 'code') && Object.hasOwn(error, 'error');
};

export const errorHandler = (error: unknown) => {
    if (!error) return;

    let message = String(error);

    if (error instanceof Error) {
        try {
            const apiError = JSON.parse(error.message);
            if (errorIsApiError(apiError)) {
                message = apiError.error;
            }
        } catch {
            message = error.message;
        }
    }

    messageStore.error(message);
};
