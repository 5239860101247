import { getLoginInput, LoginInput } from '../../dto';
import { FormikConfig } from 'formik';
import * as yup from 'yup';
import { isEmailValid } from '../../utils/user';

export type IFormValues = {
    email: string;
    password: string;
};

export const getLoginFormik = (onLogin: (input: LoginInput) => Promise<unknown>): FormikConfig<IFormValues> => {
    return {
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: yup.object().shape({
            email: yup
                .string()
                .trim()
                .required('Укажите email')
                .test({
                    name: 'Email should be valid',
                    test: function (value, { createError }) {
                        if (!isEmailValid(value))
                            return createError({
                                path: 'email',
                                message: 'Некорректный email',
                            });

                        return true;
                    },
                }),
            password: yup.string().trim().required('Укажите пароль'),
        }),
        onSubmit: async (values: IFormValues, formikHelpers) => {
            const { email, password } = values;

            if (!email || !password) {
                return;
            }

            return onLogin(getLoginInput({ email, password })).then(() => formikHelpers.resetForm());
        },
    };
};
