import { JsonProperty, JsonSerializer, throwError } from 'typescript-json-serializer';
import { JsonPropertyOptions } from 'typescript-json-serializer/dist/json-property';
import moment from 'moment';

export const serializer = new JsonSerializer({
    errorCallback: throwError,
});

export const DEFAULT_DATE = new Date();

const SERVER_DATE_FORMAT = 'DD.MM.YYYY';

export const deserializeDate = (s: string | null | undefined) => (s ? moment(s, SERVER_DATE_FORMAT).toISOString() : s);

export const serializeDate = (d: Date | null | undefined) => (d ? moment(d).format(SERVER_DATE_FORMAT) : d);

export const JsonDate = (options?: JsonPropertyOptions) =>
    JsonProperty({ ...options, beforeDeserialize: deserializeDate, beforeSerialize: serializeDate });

const validateEnum = (enumType: object, value: unknown) => {
    if (typeof value !== 'string' || !Object.values(enumType).includes(value))
        throw new TypeError('Wrong enum value: ' + String(value));

    return value;
};

export const JsonEnum = ({ type, ...rest }: Omit<JsonPropertyOptions, 'type'> & { type: object }) =>
    JsonProperty({
        ...rest,
        beforeDeserialize: (v: string | null) => v?.toUpperCase().replace(/\s/g, '_'),
        afterDeserialize: val => validateEnum(type, val),
        afterSerialize: (v: string | null) => v?.toLowerCase(),
    });
