import { Project } from '../../dto';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { EmployeeBadge } from '../../components/EmployeeBadge';
import { ProjectForm } from './ProjectForm';
import { useNavigate } from 'react-router';
import { ProjectInfo } from '../../components/ProjectInfo';
import { useMobileLayout } from '../../hooks';

const MAX_PARTICIPANTS_DESKTOP = 20;
const MAX_PARTICIPANTS_MOBILE = 10;

type Props = {
    project: Project;
    onArchive: () => void;
    onRestore: () => void;
    clickable?: boolean;
    disabled?: boolean;
};

const Root = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
});

type ContainerProps = {
    clickable: boolean;
    archived: boolean;
    active: boolean;
};

const Container = styled('div')<ContainerProps>(({ theme, archived, clickable, active }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    background: archived ? '#CCCCCC' : active ? '#EEF5EE' : '#EEEEEE',
    padding: theme.spacing(2),
    cursor: clickable ? 'pointer' : 'inherit',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5),
    },
}));

const Participants = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    overflowX: 'auto',
}));

export const ProjectCard: FC<Props> = ({ project, onArchive, onRestore, disabled = false, clickable = false }) => {
    const [open, setOpen] = useState<boolean>(false);

    const [active, setActive] = useState<boolean>(false);

    const isMobile = useMobileLayout();

    const navigate = useNavigate();

    const navigateToProject = useCallback(() => {
        if (!clickable) return;

        navigate(`/projects/${project.id}`);
    }, [project, navigate, clickable]);

    const participants = useMemo(
        () => project.participants.slice(0, isMobile ? MAX_PARTICIPANTS_MOBILE : MAX_PARTICIPANTS_DESKTOP),
        [project, isMobile]
    );

    const participantsLeft = useMemo(() => project.participants.length - participants.length, [project, participants]);

    return (
        <Root>
            <Container
                onMouseEnter={() => setActive(true)}
                onMouseLeave={() => setActive(false)}
                onClick={navigateToProject}
                archived={project.isArchived}
                clickable={clickable && !disabled}
                active={active}
            >
                <ProjectInfo
                    project={project}
                    onEdit={() => setOpen(true)}
                    showButtons={active || isMobile}
                    onArchive={() => (onArchive ? onArchive() : undefined)}
                    onRestore={() => (onRestore ? onRestore() : undefined)}
                    disabled={disabled}
                />
                {project.participants.length > 0 ? (
                    <Participants>
                        {participants.map(p => (
                            <EmployeeBadge employee={p} key={p.id} size="small" />
                        ))}
                        {participantsLeft > 0 && (
                            <Typography variant={isMobile ? 'caption' : 'body1'}>{`+${participantsLeft}`}</Typography>
                        )}
                    </Participants>
                ) : null}
            </Container>
            <ProjectForm project={project} open={open} onClose={() => setOpen(false)} />
        </Root>
    );
};
