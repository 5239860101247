import { Snackbar, Alert, Slide, SlideProps } from '@mui/material';
import React from 'react';
import { Message, messageStore } from '../../stores/messageStore';

type Props = {
    message: Message;
    index: number;
};

const anchorOrigin = { vertical: 'top', horizontal: 'right' } as const;

function TransitionRight(props: SlideProps) {
    return <Slide {...props} direction="left" />;
}

export const MessageItem = ({ message, index }: Props) => {
    const [visible, setVisible] = React.useState(true);

    const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setVisible(false);
    };

    return (
        <Snackbar
            autoHideDuration={5000}
            onClose={handleClose}
            open={visible}
            anchorOrigin={anchorOrigin}
            TransitionComponent={TransitionRight}
            TransitionProps={{ onExited: () => messageStore.hideMessage(message) }}
            sx={{ marginTop: index * 6 }}
        >
            <Alert severity={message.type} onClose={handleClose}>
                {message.text}
            </Alert>
        </Snackbar>
    );
};
