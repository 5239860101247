import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';
import { Employee } from '../../dto';
import { FakeRequest } from '../../api/FakeRequest';
import { serializer } from '../../dto/utils';

export const archiveEmployeeRequest = !mockApi
    ? new HttpRequest<{ id: string }, Employee>({
          method: 'post',
          path: params => `/employee/${params.id}/archive`,
          mapper: data => serializer.deserializeObject(data, Employee) as Employee,
      })
    : new FakeRequest<{ id: string }, Employee>({
          fakeData: () =>
              serializer.deserializeObject(
                  {
                      id: '0',
                      name: 'Иван',
                      patronymic: 'Константинович',
                      surname: 'Образец',
                  },
                  Employee
              ) as Employee,
      });
