import { FC, useCallback } from 'react';
import { Box, Button, Link, Paper, Stack, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { LoginInput } from '../../dto';
import { Layout } from '../../components/Layout';
import { Form, Formik } from 'formik';
import { FormikPasswordField, FormikTextField } from '../../components/FormikFields';
import { loginRequest } from './loginRequest';
import { authStore } from '../../stores/authStore';
import { getLoginFormik } from './LoginFormik';
import { refreshUser } from '../../utils/user';

const Root = styled(Paper)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    boxSizing: 'border-box',
}));

const ButtonArea = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
});

export const LoginPage: FC = () => {
    const navigate = useNavigate();

    const login = useCallback(
        async (input: LoginInput) =>
            loginRequest
                .request(input)
                .then(token => authStore.setAuthToken(token))
                .then(refreshUser)
                .then(() => navigate('/')),
        [navigate]
    );

    return (
        <Layout title="Вход" actions="none" showSelector={false}>
            <Root>
                <Formik {...getLoginFormik(login)}>
                    {formik => (
                        <Form>
                            <Stack spacing={2} flexDirection="column">
                                <FormikTextField
                                    name={'email'}
                                    formik={formik}
                                    disabled={formik.isSubmitting}
                                    label="Email"
                                />
                                <FormikPasswordField
                                    name={'password'}
                                    formik={formik}
                                    disabled={formik.isSubmitting}
                                    label="Пароль"
                                />
                                <ButtonArea>
                                    <Link href="/register">
                                        <Typography>Регистрация</Typography>
                                    </Link>
                                    <Link href="/request-reset">
                                        <Typography>Восстановить пароль</Typography>
                                    </Link>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={formik.isSubmitting || !formik.touched || !formik.isValid}
                                    >
                                        Войти
                                    </Button>
                                </ButtonArea>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Root>
        </Layout>
    );
};
