import { ProjectDetails as ProjectDetailsEntity, WorkloadInput } from '../../dto';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { ProjectInfo } from '../../components/ProjectInfo';
import { Box, Button, IconButton, Paper } from '@mui/material';
import { styled } from '@mui/material';
import { Add, AddCircleOutlined, ArrowBackOutlined } from '@mui/icons-material';
import { WorkloadChart } from '../../components/WorkloadChart';
import { observer } from 'mobx-react';
import { formatFullName } from '../../utils/formatters';
import { WorkloadRowInfo, WorkloadTable } from '../../components/WorkloadTable';
import { messageStore } from '../../stores/messageStore';
import { projectDetailsStore } from '../../stores/projectDetailsStore';
import { EmployeeBadge } from '../../components/EmployeeBadge';
import { removeWorkloadRequest, WorkloadForm } from '../common/WorkloadForm';
import { createWorkloadRequest } from '../common/WorkloadForm/createWorkloadRequest';
import { updateWorkloadRequest } from '../common/WorkloadForm/updateWorkloadRequest';
import { useNavigate } from 'react-router';
import { useMobileLayout } from '../../hooks';

type Props = {
    data: ProjectDetailsEntity;
};

const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
    },
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
}));

const HeaderBlock = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
}));

const InfoBlock = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    height: 'calc(50vh - 90px)',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        height: 'calc(50vh - 72px)',
    },
}));

const EmployeeData = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
}));

const EmployeeInfo: FC<{ row: WorkloadRowInfo }> = ({ row }) => {
    return (
        <EmployeeData>
            <EmployeeBadge employee={row.employee} />
            {formatFullName(row.employee)}
        </EmployeeData>
    );
};

export const ProjectDetails: FC<Props> = observer(({ data }) => {
    const [open, setOpen] = useState<boolean>(false);

    const [selectedId, setSelectedId] = useState<string | undefined>(undefined);

    const navigate = useNavigate();

    const isMobile = useMobileLayout();

    const workloadChartRows = useMemo(
        () =>
            data.workload.map(w => ({
                id: w.id,
                from: w.from,
                to: w.to,
                section: {
                    id: w.employee.id,
                    label: formatFullName(w.employee),
                },
                percent: w.percent,
                comment: w.comment,
            })),
        [data]
    );

    const workloadRows: WorkloadRowInfo[] = useMemo(
        () =>
            data.workload.map(w => ({
                project: {
                    id: data.id,
                    name: data.name,
                    from: data.from,
                    to: data.to,
                    hasLogo: data.hasLogo,
                    isArchived: data.isArchived,
                },
                employee: w.employee,
                workload: w,
            })),
        [data]
    );

    const onRemove = useCallback(
        (row: WorkloadRowInfo) =>
            removeWorkloadRequest
                .request({ id: row.workload.id })
                .then(() => messageStore.success('Загрузка удалена'))
                .then(() => projectDetailsStore.refresh(row.project.id)),
        []
    );

    const onCreate = useCallback(
        async (input: WorkloadInput) => {
            return createWorkloadRequest
                .request(input)
                .then(() => messageStore.success('Загрузка создана'))
                .then(() => projectDetailsStore.refresh(data.id));
        },
        [data]
    );

    const onUpdate = useCallback(
        async (input: WorkloadInput) => {
            return updateWorkloadRequest
                .request(input)
                .then(() => messageStore.success('Загрузка обновлена'))
                .then(() => projectDetailsStore.refresh(data.id));
        },
        [data]
    );

    return (
        <Root>
            <InfoBlock>
                <Header>
                    <HeaderBlock>
                        <IconButton onClick={() => navigate('/project')}>
                            <ArrowBackOutlined />
                        </IconButton>
                        <ProjectInfo project={data} />
                    </HeaderBlock>
                    {isMobile ? (
                        <IconButton
                            onClick={() => {
                                setOpen(() => true);
                            }}
                            disabled={projectDetailsStore.isLoading}
                            size="large"
                            color="primary"
                        >
                            <AddCircleOutlined />
                        </IconButton>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={() => {
                                setOpen(() => true);
                            }}
                            endIcon={<Add />}
                            disabled={projectDetailsStore.isLoading}
                        >
                            Создать
                        </Button>
                    )}
                </Header>
                <WorkloadTable
                    data={workloadRows}
                    onRemove={onRemove}
                    onCreate={onCreate}
                    onUpdate={onUpdate}
                    renderSection={row => <EmployeeInfo row={row} />}
                    sectionHeader="Сотрудник"
                    options={{ allowProjectEdit: false, allowEmployeeEdit: true }}
                    rowId={selectedId}
                    setRowId={setSelectedId}
                    disabled={projectDetailsStore.isLoading}
                />
            </InfoBlock>
            <InfoBlock>
                <WorkloadChart
                    data={workloadChartRows}
                    showTotalWorkload={false}
                    onCellClick={setSelectedId}
                    selectedId={selectedId}
                    disabled={projectDetailsStore.isLoading}
                />
            </InfoBlock>
            <WorkloadForm
                project={data}
                open={open}
                onClose={() => setOpen(false)}
                onCreate={onCreate}
                onUpdate={onUpdate}
                options={{ allowProjectEdit: false, allowEmployeeEdit: true }}
            />
        </Root>
    );
});
