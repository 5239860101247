import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';
import { RegisterInput } from '../../dto';
import { serializer } from '../../dto/utils';
import { FakeRequest } from '../../api/FakeRequest';

export const registerRequest = !mockApi
    ? new HttpRequest<RegisterInput, unknown>({
          method: 'post',
          path: () => '/user/register',
          mapper: data => data,
          body: data => serializer.serializeObject(data) || {},
      })
    : new FakeRequest<RegisterInput, unknown>({
          fakeData: () => {},
      });
