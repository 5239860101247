import { userRequest } from '../stores/userRequest';
import { authStore } from '../stores/authStore';

export const MIN_PASSWORD_LENGTH = 6;

export const refreshUser = async () => {
    userRequest.request({}).then(user => authStore.setUser(user));
};

const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmailValid = (email: string) => !!email.toLowerCase().match(emailPattern);
