import React, { FC, useCallback } from 'react';
import { Project, ProjectInput } from '../../../dto';
import { getProjectFormikConfig } from './ProjectFormik';
import { createProjectRequest } from './createProjectRequest';
import { messageStore } from '../../../stores/messageStore';
import { updateProjectRequest } from './updateProjectRequest';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid2,
    LinearProgress,
    styled,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { projectsStore } from '../../../stores/projectsStore';
import { FormikDateField, FormikFileField, FormikTextField } from '../../../components/FormikFields';

type Props = {
    project?: Project;
    open: boolean;
    onClose: (id?: string) => void;
};

const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}));

export const ProjectForm: FC<Props> = ({ project, open, onClose }) => {
    const onCreate = useCallback(
        async (input: ProjectInput) => {
            return createProjectRequest
                .request(input)
                .then(result => onClose(result.id))
                .then(() => messageStore.success('Проект создан'))
                .then(projectsStore.refresh);
        },
        [onClose]
    );

    const onUpdate = useCallback(
        async (input: ProjectInput) => {
            return updateProjectRequest
                .request(input)
                .then(result => onClose(result.id))
                .then(() => messageStore.success('Проект обновлен'))
                .then(projectsStore.refresh);
        },
        [onClose]
    );

    return (
        <Formik {...getProjectFormikConfig(project ? onUpdate : onCreate, project)}>
            {formik => (
                <Dialog open={open} maxWidth="md" fullWidth disableRestoreFocus>
                    <Form>
                        <DialogTitle>{project ? 'Изменить проект' : 'Создать проект'}</DialogTitle>
                        <DialogContent>
                            <Root>
                                {formik.isSubmitting && <LinearProgress />}
                                <Grid2 container spacing={2}>
                                    <Grid2 size={12}>
                                        <FormikTextField
                                            name={'name'}
                                            formik={formik}
                                            label="Название"
                                            required
                                            autoFocus
                                            disabled={formik.isSubmitting}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <FormikDateField
                                            name={'from'}
                                            formik={formik}
                                            label="Дата начала"
                                            required
                                            disabled={formik.isSubmitting}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <FormikDateField
                                            name={'to'}
                                            formik={formik}
                                            label="Дата завершения"
                                            required
                                            disabled={formik.isSubmitting}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <FormikFileField
                                            name={'logo'}
                                            extensionName={'logoExtension'}
                                            formik={formik}
                                            hasFile={project?.hasLogo}
                                            label="Лого"
                                            disabled={formik.isSubmitting}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formik.values.isVacation}
                                                    onChange={e =>
                                                        formik.setFieldValue('isVacation', e.target.checked, true)
                                                    }
                                                />
                                            }
                                            label={'Отпуска'}
                                            disabled={formik.isSubmitting}
                                        />
                                    </Grid2>
                                </Grid2>
                                <Divider />
                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            formik.resetForm();
                                            onClose();
                                        }}
                                        disabled={formik.isSubmitting}
                                    >
                                        Отмена
                                    </Button>
                                    <Button variant="contained" type="submit" disabled={formik.isSubmitting}>
                                        Сохранить
                                    </Button>
                                </DialogActions>
                            </Root>
                        </DialogContent>
                    </Form>
                </Dialog>
            )}
        </Formik>
    );
};
