import { FakeRequest } from '../../api/FakeRequest';
import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';
import { serializer } from '../../dto/utils';
import { ActivationCodeStatus, ActivationResult } from '../../dto';

export const activateRequest = !mockApi
    ? new HttpRequest<{ code: string }, ActivationResult>({
          method: 'get',
          path: input => `/activation/${input.code}`,
          mapper: data => serializer.deserializeObject(data, ActivationResult) as ActivationResult,
      })
    : new FakeRequest<{ code: string }, ActivationResult>({
          fakeData: () =>
              serializer.deserializeObject(
                  { status: ActivationCodeStatus.ACTIVATED },
                  ActivationResult
              ) as ActivationResult,
      });
