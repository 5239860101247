import { action, makeObservable, observable } from 'mobx';
import { Project } from '../dto';
import { projectsRequest } from '../views/ProjectsPage/projectsRequest';

class ProjectsStore {
    constructor() {
        makeObservable(this);
    }

    @observable
    data?: Project[];

    @observable
    isLoading = false;

    @observable
    error: Error | null = null;

    @observable
    includeArchive: boolean = false;

    @action
    setIncludeArchive(value: boolean): void {
        this.includeArchive = value;
        this.refresh();
    }

    @action refresh = () => {
        this.isLoading = true;
        this.error = null;

        projectsRequest
            .request({ includeArchive: this.includeArchive })
            .then(result => {
                result.sort((a, b) => +a.isArchived - +b.isArchived);
                this.data = result;
            })
            .catch(error => (this.error = error))
            .finally(() => {
                this.isLoading = false;
            });
    };
}

export const projectsStore = new ProjectsStore();
