import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { FormikContextType } from 'formik';
import React from 'react';

type FormikPasswordFieldProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends string ? TFieldName : never;
    formik: FormikContextType<TValues>;
} & Omit<TextFieldProps, 'value'>;

export function FormikPasswordField<TValues extends {}, TFieldName extends keyof TValues & string>({
    name,
    formik,
    onChange,
    disabled,
    ...textFieldProps
}: FormikPasswordFieldProps<TValues, TFieldName>) {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword(show => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        // To prevent focus change
        event.preventDefault();
    };

    return (
        <TextField
            type={showPassword ? 'text' : 'password'}
            fullWidth
            autoComplete="off"
            name={name}
            value={formik.values[name]}
            onChange={onChange || formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[name] && !!formik.errors[name]}
            helperText={formik.touched[name] && formik.errors[name]?.toString()}
            disabled={disabled || formik.isSubmitting}
            margin="dense"
            slotProps={{
                input: {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                tabIndex={-1}
                            >
                                {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                            </IconButton>
                        </InputAdornment>
                    ),
                },
            }}
            {...textFieldProps}
        />
    );
}
