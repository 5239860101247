import moment from 'moment';
import 'moment/locale/ru';
import { Employee } from '../dto';

export const formatDate = (date: Date) => {
    return moment(date).format('DD.MM.YYYY');
};

export const formatPercent = (percent: number) => {
    return `${(percent * 100).toFixed(2)}%`;
};

export const formatHours = (hours: number) => {
    return `${hours.toFixed(2)}  ч.`;
};

export const formatFullName = (employee: Pick<Employee, 'name' | 'surname' | 'patronymic'>) => {
    return `${employee.surname} ${employee.name}${employee.patronymic ? ` ${employee.patronymic}` : ''}`;
};

export const formatInitials = (employee: Pick<Employee, 'name' | 'surname' | 'patronymic'>) => {
    return `${employee.name[0].toUpperCase()}${employee.surname[0].toUpperCase()}`;
};

export const formatMonth = (date: Date) =>
    `${capitalizeFirstLetter(moment(date).format('MMMM'))} '${moment(date).format('YY')}`;

export const formatWeekDay = (date: Date) =>
    `${capitalizeFirstLetter(moment(date).format('ddd'))} ${moment(date).format('DD')}`;

export const formatInterval = (dateFrom: Date, dateTo: Date): string => {
    const dayDiff = moment(dateTo).diff(moment(dateFrom), 'days', true) + 1;

    if (dayDiff < 21) return `${dayDiff} дн.`;

    if (dayDiff < 9 * 7) return `${moment(dateTo).diff(moment(dateFrom), 'weeks') + 1} нед.`;

    return `${moment(dateTo).diff(moment(dateFrom), 'months') + 1} нед.`;
};

export const formatPercentRange = (min: number | null, max: number | null): string => {
    if (max === null) return '';

    if (min === null) return `до ${formatPercent(max)}`;

    if (min === max) return formatPercent(max);

    return `${formatPercent(min)} - ${formatPercent(max)}`;
};

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
