import { FakeRequest } from '../../api/FakeRequest';
import { User } from '../../dto';
import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';
import { serializer } from '../../dto/utils';

export const usersRequest = !mockApi
    ? new HttpRequest<{}, User[]>({
          method: 'get',
          path: () => '/user',
          mapper: data => serializer.deserializeObjectArray(data, User) as User[],
      })
    : new FakeRequest<{}, User[]>({
          fakeData: () =>
              serializer.deserializeObjectArray(
                  [
                      {
                          id: '11',
                          name: 'Admin',
                          isAdmin: true,
                          isVerified: true,
                          isBlocked: false,
                      },
                      {
                          id: '12',
                          name: 'John',
                          isAdmin: false,
                          isVerified: true,
                          isBlocked: true,
                      },
                  ],
                  User
              ) as User[],
      });
