import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { Employee } from './EmployeeDto';
import { Project } from './ProjectDto';
import { Workload } from './WorkloadDto';

@JsonObject()
export class EmployeeWorkload extends Workload {
    @JsonProperty({ type: Project })
    project: Project = new Project();
}

@JsonObject()
export class EmployeeDetails extends Employee {
    @JsonProperty({ type: EmployeeWorkload })
    workload: EmployeeWorkload[] = [];
}
