import { JsonObject, JsonProperty } from 'typescript-json-serializer';

@JsonObject()
export class ResetPasswordInput {
    @JsonProperty()
    password: string = '';

    @JsonProperty()
    confirmPassword: string = '';

    @JsonProperty()
    code: string = '';
}

export const getResetPasswordInput = (input: ResetPasswordInput): ResetPasswordInput =>
    Object.assign(new ResetPasswordInput(), input);
