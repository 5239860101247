import { GroupIntervalInfo, GroupRow, WorkloadGroupColumn } from './WorkloadChart.types';
import { formatMonth, formatWeekDay } from '../../utils/formatters';
import moment from 'moment/moment';
import { getDateRange, getWorkloadValue, WorkloadChartRow } from '../../utils/workload';
import { workDaysIntervalLength } from '../../utils/dates';
import { Interval, PlanningMode, WorkloadColumn } from '../../utils/types';

const getMonth = (date: Date) => moment(date).format('YYYY-MM');

export const generateColumns = (
    workload: WorkloadChartRow[],
    interval: Interval,
    additionalIntervalsCount: number = 0
): WorkloadColumn[] => {
    const dates = getDateRange(workload, interval);

    if (!dates) return [];

    if (additionalIntervalsCount > 0) {
        switch (interval) {
            case 'day':
                dates.min.setDate(dates.min.getDate() - additionalIntervalsCount);
                dates.max.setDate(dates.max.getDate() + additionalIntervalsCount);
                break;
            case 'week':
                dates.min.setDate(dates.min.getDate() - 7 * additionalIntervalsCount);
                dates.max.setDate(dates.max.getDate() + 7 * additionalIntervalsCount);
                break;
            case 'month':
                dates.min.setMonth(dates.min.getMonth() - additionalIntervalsCount);
                dates.max.setMonth(dates.max.getMonth() + additionalIntervalsCount);
                break;
        }
    }

    let cur = dates.min;
    const lastTime = dates.max.getTime();

    const result: WorkloadColumn[] = [];

    while (cur.getTime() <= lastTime) {
        let next = new Date(cur);
        let curStart = new Date(cur);
        let curEnd = new Date();
        switch (interval) {
            case 'day':
                next.setDate(cur.getDate() + 1);
                curEnd = curStart;
                break;
            case 'week':
                next.setDate(cur.getDate() + 7);
                curEnd = new Date(next);
                curEnd.setDate(next.getDate() - 1);
                break;
            case 'month':
                next.setMonth(cur.getMonth() + 1);
                curEnd = new Date(next);
                curEnd.setDate(next.getDate() - 1);
                break;
        }

        cur = next;

        result.push({
            key: curStart.toISOString(),
            label: moment(curStart).format(interval === 'month' ? 'MM-YY' : formatWeekDay(curStart)),
            from: curStart,
            to: curEnd,
        });
    }

    return result;
};

export const getGroupColumns = (data: WorkloadColumn[]) =>
    data.reduce(
        (
            acc: {
                month: string;
                columns: WorkloadGroupColumn[];
                prevColumn: WorkloadGroupColumn | null;
                spanCount: number;
            },
            item,
            ind
        ) => {
            const curMonth = getMonth(item.from);

            if (curMonth !== acc.month) {
                if (acc.prevColumn) {
                    acc.prevColumn.spanCount = acc.spanCount;
                }
                const newColumn: WorkloadGroupColumn = {
                    id: item.from.toISOString(),
                    label: formatMonth(item.from),
                    spanCount: 1,
                };
                acc.columns.push(newColumn);

                return {
                    month: curMonth,
                    columns: acc.columns,
                    prevColumn: newColumn,
                    spanCount: 1,
                };
            } else {
                const spanCount = acc.spanCount + 1;

                if (ind === data.length - 1 && acc.prevColumn) {
                    acc.prevColumn.spanCount = spanCount;
                }

                return {
                    month: acc.month,
                    columns: acc.columns,
                    prevColumn: acc.prevColumn,
                    spanCount,
                };
            }
        },
        { month: '', columns: [], prevColumn: null, spanCount: 0 }
    ).columns;

export const getGroupRows = (columns: WorkloadColumn[], data: WorkloadChartRow[], mode: PlanningMode) => {
    const result = columns.reduce(
        (acc: GroupRow[], item) =>
            data.reduce((dataAcc, dataItem) => {
                if (!dataAcc.find(i => i.section.id === dataItem.section.id))
                    dataAcc.push({
                        section: dataItem.section,
                        itemsMap: new Map<string, GroupIntervalInfo>(),
                    });

                const curRow = dataAcc.find(i => i.section.id === dataItem.section.id)!;

                if (item.from.getTime() <= dataItem.to.getTime() && item.to.getTime() >= dataItem.from.getTime()) {
                    if (!curRow.itemsMap.has(item.key))
                        curRow.itemsMap.set(item.key, {
                            total: 0,
                            totalPercent: 0,
                            sections: [],
                        });

                    const changingItem = curRow.itemsMap.get(item.key)!;
                    const percent = getPartialPercent(dataItem, item) || 0;
                    const value = getWorkloadValue({ from: item.from, to: item.to, percent }, mode);
                    changingItem.total += value;
                    changingItem.totalPercent += percent;
                    changingItem.sections.push({
                        id: dataItem.id,
                        label: dataItem.comment || 'Без комментария',
                        value,
                    });
                }

                return dataAcc;
            }, acc),
        []
    );

    return result;
};

export const getTotalWorkload = (columns: WorkloadColumn[], items: GroupRow[], totalLabel: string): GroupRow => {
    return columns.reduce(
        (acc: GroupRow, column) => {
            const curIntervalInfo: GroupIntervalInfo = {
                total: 0,
                totalPercent: 0,
                sections: [],
            };

            items.forEach(row => {
                const item = row.itemsMap.get(column.key);

                if (item) {
                    curIntervalInfo.total += item.total;
                    curIntervalInfo.totalPercent += item.totalPercent;
                    curIntervalInfo.sections.push(
                        ...item.sections.map(s => ({
                            id: `${row.section.id}-${s.id}`,
                            label: `${row.section.label}. ${s.label}`,
                            value: s.value,
                        }))
                    );
                }
            });

            if (curIntervalInfo.sections.length > 0) {
                acc.itemsMap.set(column.key, curIntervalInfo);
            }

            return acc;
        },
        {
            section: {
                id: 'total',
                label: totalLabel,
            },
            itemsMap: new Map<string, GroupIntervalInfo>(),
        }
    );
};

export const getPartialPercent = (row: WorkloadChartRow, column: WorkloadColumn): number | null => {
    if (row.percent === 0) return 0;

    const rowFrom = row.from.getTime();
    const rowTo = row.to.getTime();
    const columnFrom = column.from.getTime();
    const columnTo = column.to.getTime();

    if (rowFrom > columnTo || rowTo < columnFrom) return null;

    const left = rowFrom > columnFrom ? row.from : column.from;
    const right = columnTo > rowTo ? row.to : column.to;

    return (row.percent * workDaysIntervalLength(left, right)) / workDaysIntervalLength(column.from, column.to);
};

export const getPercentColor = (percent: number) => {
    if (percent < 0.6) return 'lightgray';
    if (percent > 0.8 && percent < 1) return 'gold';
    if (percent > 1) return 'crimson';

    return 'palegreen';
};
