import { action, makeObservable, observable } from 'mobx';
import { User } from '../dto';
import { usersRequest } from '../views/AdminPage/usersRequest';

class UsersStore {
    constructor() {
        makeObservable(this);
    }

    @observable
    data?: User[];

    @observable
    isLoading = false;

    @observable
    error: Error | null = null;

    @action
    refresh = () => {
        this.isLoading = true;
        this.error = null;

        usersRequest
            .request({})
            .then(result => {
                this.data = result;
            })
            .catch(error => (this.error = error))
            .finally(() => {
                this.isLoading = false;
            });
    };
}

export const usersStore = new UsersStore();
