import { mockApi } from '../../../api/constants';
import { HttpRequest } from '../../../api/HttpRequest';
import { FakeRequest } from '../../../api/FakeRequest';
import { serializer } from '../../../dto/utils';

export const removeWorkloadRequest = !mockApi
    ? new HttpRequest<{ id: string }, void>({
          method: 'delete',
          path: () => '/workload/',
          mapper: data => data,
          body: params => serializer.serializeObject(params) || {},
      })
    : new FakeRequest<{ id: string }, void>({
          fakeData: () => undefined,
      });
