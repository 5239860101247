import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';
import { ProfileInput } from '../../dto';
import { serializer } from '../../dto/utils';
import { FakeRequest } from '../../api/FakeRequest';

export const updateProfileRequest = !mockApi
    ? new HttpRequest<ProfileInput, unknown>({
          method: 'put',
          path: () => '/user',
          mapper: data => data,
          body: data => serializer.serializeObject(data) || {},
      })
    : new FakeRequest<ProfileInput, unknown>({
          fakeData: () => {},
      });
