import { Box, Button, Paper, Stack, styled, TextField } from '@mui/material';
import { FC, useCallback } from 'react';
import { ProfileInput } from '../../dto';
import { messageStore } from '../../stores/messageStore';
import { Layout } from '../../components/Layout';
import { Form, Formik } from 'formik';
import { FormikPasswordField } from '../../components/FormikFields';
import { updateProfileRequest } from './updateProfileRequest';
import { getProfileFormik } from './ProfileFormik';
import { authStore } from '../../stores/authStore';

const Root = styled(Paper)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    boxSizing: 'border-box',
}));

const ButtonArea = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
});

export const ProfilePage: FC = () => {
    const changePassword = useCallback(
        async (input: ProfileInput) =>
            updateProfileRequest.request(input).then(() => messageStore.success('Пароль успешно обновлен')),
        []
    );

    return (
        <Layout title="Профиль" showSelector={false}>
            <Root>
                <Formik {...getProfileFormik(changePassword)}>
                    {formik => (
                        <Form>
                            <Stack spacing={2} flexDirection="column">
                                <TextField value={authStore.user?.email} disabled />
                                <FormikPasswordField
                                    name={'password'}
                                    formik={formik}
                                    disabled={formik.isSubmitting}
                                    label="Пароль"
                                />
                                <FormikPasswordField
                                    name={'confirmPassword'}
                                    formik={formik}
                                    disabled={formik.isSubmitting}
                                    label="Подтверждение"
                                />
                                <ButtonArea>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={formik.isSubmitting || !formik.touched || !formik.isValid}
                                    >
                                        Сохранить
                                    </Button>
                                </ButtonArea>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Root>
        </Layout>
    );
};
