import moment from 'moment';
import { Interval, PlanningMode } from './types';
import { formatHours, formatPercent } from './formatters';
import { workDaysIntervalLength } from './dates';

const WORK_HOURS_PER_DAY = 8;

export type WorkloadChartRow = {
    id: string;
    section: {
        id: string;
        label: string;
    };
    from: Date;
    to: Date;
    percent: number;
    comment?: string;
};

type DateRange = {
    min: Date;
    max: Date;
};

export const getDateRange = (workload: WorkloadChartRow[], interval: Interval): DateRange | null => {
    if (workload.length === 0) return null;

    const dates = workload.reduce(
        (acc, item) => {
            return {
                min: acc.min.getTime() > item.from.getTime() ? new Date(item.from) : acc.min,
                max: acc.max.getTime() < item.to.getTime() ? new Date(item.to) : acc.max,
            };
        },
        {
            min: new Date(workload[0].from),
            max: new Date(workload[0].to),
        }
    );

    if (interval === 'week') {
        dates.min = moment(dates.min).weekday(0).toDate();
        dates.max = moment(dates.max).weekday(0).toDate();
    }

    if (interval === 'month') {
        dates.min = moment(dates.min).date(1).toDate();
        dates.max = moment(dates.max).date(1).toDate();
    }

    return dates;
};

export const getIntervalStartForDate = (date: Date, interval: Interval): Date => {
    switch (interval) {
        case 'day':
            return date;
        case 'week':
            return moment(date).weekday(0).toDate();
        case 'month':
            return moment(date).date(1).toDate();
    }
};

export const getHoursFromWorkload = (workload: { from: Date; to: Date; percent: number }) => {
    const workDays = workDaysIntervalLength(workload.from, workload.to);

    return Math.round(100 * workDays * workload.percent * WORK_HOURS_PER_DAY) / 100;
};

export const getPercentFromWorkload = (workload: { from: Date; to: Date; hours: number }) => {
    const workDays = workDaysIntervalLength(workload.from, workload.to);

    return Math.round((100 * workload.hours) / (workDays * WORK_HOURS_PER_DAY)) / 100;
};

export const getFormattedWorkloadValue = (
    workload: { from: Date; to: Date; percent: number },
    mode: PlanningMode
): string => {
    if (mode === 'percents') return formatPercent(workload.percent);

    return formatHours(getHoursFromWorkload(workload));
};

export const getWorkloadValue = (workload: { from: Date; to: Date; percent: number }, mode: PlanningMode): number => {
    if (mode === 'percents') return workload.percent;

    return getHoursFromWorkload(workload);
};
