import { FakeRequest } from '../../api/FakeRequest';
import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';
import { serializer } from '../../dto/utils';
import { ResetPasswordResult, ResetPasswordStatus } from '../../dto';

export const checkResetPasswordCodeRequest = !mockApi
    ? new HttpRequest<{ code: string }, ResetPasswordResult>({
          method: 'get',
          path: input => `/user/checkCode/${input.code}`,
          mapper: data => serializer.deserializeObject(data, ResetPasswordResult) as ResetPasswordResult,
      })
    : new FakeRequest<{ code: string }, ResetPasswordResult>({
          fakeData: () =>
              serializer.deserializeObject(
                  { status: ResetPasswordStatus.ACTIVATED },
                  ResetPasswordResult
              ) as ResetPasswordResult,
      });
