import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';
import { Project } from '../../dto';
import { FakeRequest } from '../../api/FakeRequest';
import { serializer } from '../../dto/utils';

export const restoreProjectRequest = !mockApi
    ? new HttpRequest<{ id: string }, Project>({
          method: 'post',
          path: params => `/project/${params.id}/restore`,
          mapper: data => serializer.deserializeObject(data, Project) as Project,
      })
    : new FakeRequest<{ id: string }, Project>({
          fakeData: () =>
              serializer.deserializeObject(
                  {
                      id: '1',
                      name: 'Project 1',
                      from: '01.01.2024',
                      to: '31.10.2024',
                      hasLogo: false,
                      participants: [
                          {
                              id: '11',
                              name: 'Jane',
                              surname: 'Doe',
                              hasPhoto: false,
                          },
                          {
                              id: '12',
                              name: 'John',
                              surname: 'Doe',
                              hasPhoto: false,
                          },
                      ],
                  },
                  Project
              ) as Project,
      });
