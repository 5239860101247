import React, { FC } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material';
import { EmployeeInfo } from '../../../components/EmployeeInfo';
import { SlotInfo } from './SlotInfo';
import { EmployeeExtendedAvailability } from '../widget.types';

type Props = {
    item: EmployeeExtendedAvailability;
};

const Root = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
}));

export const AvailabilityCard: FC<Props> = ({ item }) => {
    return (
        <Root>
            <EmployeeInfo employee={item} size="small" />
            <Container>
                {item.availability.map(s => (
                    <SlotInfo slot={s} key={s.from.toISOString()} />
                ))}
            </Container>
        </Root>
    );
};
