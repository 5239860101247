import { mockApi } from '../../../api/constants';
import { FakeRequest } from '../../../api/FakeRequest';
import { HttpRequest } from '../../../api/HttpRequest';
import { EmployeeAvailability } from '../../../dto';
import { serializer } from '../../../dto/utils';

export const employeesVacationRequest = !mockApi
    ? new HttpRequest<void, EmployeeAvailability[]>({
          method: 'post',
          path: () => `/employee/vacation`,
          mapper: data => serializer.deserializeObjectArray(data, EmployeeAvailability) as EmployeeAvailability[],
      })
    : new FakeRequest<void, EmployeeAvailability[]>({
          fakeData: () =>
              serializer.deserializeObjectArray(
                  [
                      {
                          id: '01',
                          name: 'Jane',
                          surname: 'Doe',
                          hasPhoto: false,
                          availability: [
                              {
                                  from: '01.01.2024',
                                  to: '31.10.2024',
                                  percent: 1,
                              },
                              {
                                  from: '01.01.2024',
                                  to: '31.07.2024',
                                  percent: 1,
                              },
                          ],
                      },
                  ],
                  EmployeeAvailability
              ) as EmployeeAvailability[],
      });
