import { FakeRequest } from '../../api/FakeRequest';
import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';
import { serializer } from '../../dto/utils';
import { ResetPasswordInput, ResetPasswordResult, ResetPasswordStatus } from '../../dto';

export const resetPasswordRequest = !mockApi
    ? new HttpRequest<ResetPasswordInput, ResetPasswordResult>({
          method: 'post',
          path: input => '/user/resetPassword',
          body: data => serializer.serializeObject(data) || {},
          mapper: data => serializer.deserializeObject(data, ResetPasswordResult) as ResetPasswordResult,
      })
    : new FakeRequest<{ code: string }, ResetPasswordResult>({
          fakeData: () =>
              serializer.deserializeObject(
                  { status: ResetPasswordStatus.ACTIVATED },
                  ResetPasswordResult
              ) as ResetPasswordResult,
      });
