import { JsonObject, JsonProperty } from 'typescript-json-serializer';

@JsonObject()
export class FileInput {
    @JsonProperty()
    content: string = '';

    @JsonProperty()
    extension: string = '';
}
