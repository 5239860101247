import { FormikConfig } from 'formik';
import { getWorkloadInput, WorkloadInput } from '../../../dto';
import * as yup from 'yup';
import { DisplayEmployee, DisplayProject, PlanningMode } from '../../../utils/types';
import { Workload } from '../../../dto/WorkloadDto';
import { getHoursFromWorkload, getPercentFromWorkload } from '../../../utils/workload';

export type IFormValues = {
    employeeId: string | null;
    projectId: string | null;
    from: Date | null;
    to: Date | null;
    percent: number | null;
    hours: number | null;
    comment: string;
};

export const getWorkloadConfig = (
    onSave: (input: WorkloadInput) => Promise<unknown>,
    mode: PlanningMode,
    project?: DisplayProject,
    employee?: DisplayEmployee,
    workload?: Workload
): FormikConfig<IFormValues> => {
    return {
        initialValues: {
            employeeId: employee?.id || null,
            projectId: project?.id || null,
            from: workload?.from || null,
            to: workload?.to || null,
            percent: workload && mode === 'percents' ? workload.percent * 100 : null,
            hours: workload && mode === 'hours' ? getHoursFromWorkload(workload) : null,
            comment: workload?.comment || '',
        },
        validationSchema: yup.object().shape({
            employeeId: yup.string().trim().required('Выберите сотрудника'),
            projectId: yup.string().trim().required('Выберите проект'),
            from: yup
                .date()
                .nullable()
                .required('Укажите дату начала загрузки')
                .test({
                    name: 'From required and less then to',
                    test: function (value, { createError, parent }) {
                        if (parent.to && parent.to.getTime() < value.getTime()) {
                            return createError({
                                path: 'to',
                                message: 'Дата завершения загрузки должна быть позже даты начала',
                            });
                        }

                        return true;
                    },
                }),
            to: yup.date().required('Укажите дату завершения загрузки'),
            percent: yup
                .number()
                .nullable()
                .test({
                    name: 'Required for percent mode',
                    test: function (value, { createError }) {
                        if (mode === 'hours') return true;

                        let message: string | null = null;
                        if (value === null || value === undefined) {
                            message = 'Укажите загрузку';
                        } else if (value < 0) {
                            message = 'Загрузка сотрудника должна быть положительным значением процента';
                        } else if (value > 100) {
                            message = 'Загрузка не может превышать 100%';
                        }

                        if (message) {
                            return createError({
                                path: ' percent',
                                message,
                            });
                        }

                        return true;
                    },
                }),
            hours: yup
                .number()
                .nullable()
                .test({
                    name: 'Required for hours mode',
                    test: function (value, { createError }) {
                        if (mode === 'percents') return true;

                        let message: string | null = null;
                        if (value === null || value === undefined) {
                            message = 'Укажите загрузку';
                        } else if (value < 0) {
                            message = 'Загрузка сотрудника должна быть положительным значением часов';
                        }

                        if (message) {
                            return createError({
                                path: ' hours',
                                message,
                            });
                        }

                        return true;
                    },
                }),
        }),
        onSubmit: async (values, formikHelpers) => {
            const { from, to, employeeId, projectId, comment, percent, hours } = values;

            if (
                !from ||
                !to ||
                !projectId ||
                !employeeId ||
                (mode === 'percents' && !percent) ||
                (mode === 'hours' && !hours)
            )
                return;

            const value =
                mode === 'percents' ? (percent || 0) / 100 : getPercentFromWorkload({ from, to, hours: hours || 0 });

            const input = getWorkloadInput({
                id: workload?.id || '',
                from,
                to,
                employeeId,
                projectId,
                percent: value,
                comment,
            });

            return onSave(input).then(() => formikHelpers.resetForm());
        },
        enableReinitialize: true,
    };
};
