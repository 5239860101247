import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import {
    BadgeOutlined,
    HomeOutlined,
    LogoutOutlined,
    NoteAltOutlined,
    SettingsOutlined,
    VerifiedUserOutlined,
} from '@mui/icons-material';
import { Divider, List, ListItem, ListItemIcon, ListItemText, styled, Typography } from '@mui/material';
import { matchRoutes, NavigateFunction, useLocation, useNavigate } from 'react-router';
import { Location } from 'history';
import { authStore } from '../../stores/authStore';
import { observer } from 'mobx-react';

enum NavigationItemType {
    LINK = 'LINK',
    ACTION = 'ACTION',
    DIVIDER = 'DIVIDER',
}

type NavigationItem =
    | {
          label: () => string;
          icon?: ReactNode;
          path: string;
          type: NavigationItemType.LINK;
          additionalLabel?: () => string;
      }
    | {
          label: () => string;
          icon?: ReactNode;
          action: (navigate: NavigateFunction) => void;
          type: NavigationItemType.ACTION;
          additionalLabel?: () => string;
      }
    | {
          type: NavigationItemType.DIVIDER;
      };

const dividerItem: NavigationItem = {
    type: NavigationItemType.DIVIDER,
};

type Props = {
    onClose: () => void;
    actions?: 'full' | 'logout' | 'none';
};

const ListGap = styled(List)(({ theme }) => ({
    gap: theme.spacing(2),
}));

const ClickableListItem = styled(ListItem)({
    cursor: 'pointer',
});

const isLocationMatchItem = (location: Location<any>, item: NavigationItem) => {
    if (item.type !== NavigationItemType.LINK) return false;

    const res = matchRoutes([{ path: item.path }], location);
    return (res?.length || 0) > 0;
};

export const DrawerItems: FC<Props> = observer(({ onClose, actions = 'full' }) => {
    const navigate = useNavigate();

    const location = useLocation();

    const onItemClick = useCallback(
        (item: NavigationItem) => {
            if (item.type === NavigationItemType.DIVIDER) return;

            if (item.type === NavigationItemType.LINK) {
                navigate(item.path);
                return;
            }

            item.action(navigate);
            onClose();
        },
        [navigate, onClose]
    );

    const items: NavigationItem[] = useMemo(() => {
        const items: NavigationItem[] = [
            {
                label: () => 'Начало',
                icon: <HomeOutlined fontSize="small" />,
                path: '/',
                type: NavigationItemType.LINK,
            },
        ];

        if (actions === 'full') {
            items.push(
                {
                    label: () => 'Проекты',
                    icon: <NoteAltOutlined fontSize="small" />,
                    path: '/project',
                    type: NavigationItemType.LINK,
                },
                {
                    label: () => 'Сотрудники',
                    icon: <BadgeOutlined fontSize="small" />,
                    path: '/employee',
                    type: NavigationItemType.LINK,
                },
                {
                    label: () => 'Профиль',
                    icon: <VerifiedUserOutlined fontSize="small" />,
                    path: '/profile',
                    type: NavigationItemType.LINK,
                    additionalLabel: () => authStore.user?.email || '',
                },
                dividerItem
            );

            if (authStore.isAdmin) {
                const adminItem: NavigationItem = {
                    label: () => 'Администрирование',
                    icon: <SettingsOutlined fontSize="small" />,
                    path: '/admin',
                    type: NavigationItemType.LINK,
                };

                items.push(adminItem, dividerItem);
            }
        }

        if (actions !== 'none') {
            const exitItem: NavigationItem = {
                label: () => 'Выйти',
                icon: <LogoutOutlined fontSize="small" />,
                action: (navigate: NavigateFunction) => {
                    authStore.logout();
                    navigate('/');
                },
                type: NavigationItemType.ACTION,
            };
            items.push(exitItem);
        }

        return items;
    }, [actions]);

    return (
        <ListGap>
            {items.map((item, ind) => {
                if (item.type === NavigationItemType.DIVIDER) return <Divider key={ind.toString()} />;

                const isCurrent = isLocationMatchItem(location, item);

                return isCurrent ? (
                    <ListItem key={item.label()}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText
                            primary={<Typography fontWeight="600">{item.label()}</Typography>}
                            secondary={item.additionalLabel && item.additionalLabel()}
                        />
                    </ListItem>
                ) : (
                    <ClickableListItem key={item.label()} onClick={() => onItemClick(item)}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText
                            primary={item.label()}
                            secondary={item.additionalLabel && item.additionalLabel()}
                        />
                    </ClickableListItem>
                );
            })}
        </ListGap>
    );
});
