import { FormikContextType } from 'formik';
import { FormHelperText, FormControl } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/ru';
import { Moment } from 'moment';
import moment from 'moment';

type FormikDateFieldProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends Date | null ? TFieldName : never;
    formik: FormikContextType<TValues>;
    openToYear?: boolean;
    label?: string;
    required?: boolean;
    disabled?: boolean;
};

export const FormikDateField = <TValues extends {}, TFieldName extends keyof TValues & string>({
    formik,
    name,
    openToYear,
    label,
    required,
    disabled,
}: FormikDateFieldProps<TValues, TFieldName>) => {
    const value = formik.values[name] as Date | null;
    const error = formik.errors[name]?.toString();
    const touched = formik.touched[name];

    const handleChange = (v: Moment | null | undefined) => {
        formik.setFieldTouched(name, true).then(() => formik.setFieldValue(name, v?.toDate() || null, true));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ruRU">
            <FormControl variant="outlined" fullWidth>
                <DatePicker
                    label={required ? label + ' *' : label}
                    views={['year', 'month', 'day']}
                    format="DD.MM.YYYY"
                    openTo={openToYear ? 'year' : undefined}
                    localeText={{ toolbarTitle: '', cancelButtonLabel: 'Отмена' }}
                    value={moment(value)}
                    onChange={handleChange}
                    disabled={disabled}
                />
                {touched && error && <FormHelperText error>{error}</FormHelperText>}
            </FormControl>
        </LocalizationProvider>
    );
};
