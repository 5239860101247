import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { Employee } from './EmployeeDto';
import { Workload } from './WorkloadDto';
import { ProjectBase } from './ProjectDto';

@JsonObject()
export class ProjectWorkload extends Workload {
    @JsonProperty({ type: Employee })
    employee: Employee = new Employee();
}

@JsonObject()
export class ProjectDetails extends ProjectBase {
    @JsonProperty({ type: ProjectWorkload })
    workload: ProjectWorkload[] = [];
}
