import { Employee } from './EmployeeDto';
import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { DEFAULT_DATE, JsonDate } from './utils';

@JsonObject()
export class ProjectBase {
    @JsonProperty()
    id: string = '';

    @JsonProperty()
    name: string = '';

    @JsonDate()
    from: Date = DEFAULT_DATE;

    @JsonDate()
    to: Date = DEFAULT_DATE;

    @JsonProperty()
    hasLogo: boolean = false;

    @JsonProperty()
    isVacation: boolean = false;

    @JsonProperty()
    isArchived: boolean = false;
}

@JsonObject()
export class Project extends ProjectBase {
    @JsonProperty({ type: Employee })
    participants: Employee[] = [];
}
