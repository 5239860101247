import { makeObservable, observable, action, computed } from 'mobx';
import { User, UserStatus } from '../dto';

const STORAGE_KEY = 'resourcePlannerToken';

class AuthStore {
    constructor() {
        makeObservable(this);

        this.loadFromStorage();
    }

    @observable
    token: string | null = null;

    @observable
    user: User | null = null;

    loadFromStorage() {
        try {
            const str = localStorage.getItem(STORAGE_KEY);
            if (str) this.setAuthToken(str);
        } catch (_) {}
    }

    @action
    setAuthToken = (token: string) => {
        this.token = token;
        this.saveToStorage();
    };

    @computed
    get isLoggedIn() {
        return !!this.token;
    }

    @computed
    get isAdmin() {
        return !!this.user?.isAdmin;
    }

    @computed
    get canAccess() {
        return this.user?.status === UserStatus.APPROVED && !this.user?.isBlocked;
    }

    saveToStorage = () => {
        if (this.token) {
            localStorage.setItem(STORAGE_KEY, this.token);
        } else {
            localStorage.removeItem(STORAGE_KEY);
        }
    };

    @action
    logout = () => {
        this.token = null;
        this.user = null;
        this.saveToStorage();
    };

    @action
    setUser = (newUser: User | null) => {
        this.user = newUser;
    };
}

export const authStore = new AuthStore();
