import { FormikConfig } from 'formik';
import { getProjectInput, Project, ProjectInput } from '../../../dto';
import * as yup from 'yup';
import { toBase64 } from '../../../utils/files';

export type IFormValues = {
    name: string;
    from: Date | null;
    to: Date | null;
    logo: File | null;
    logoExtension: string;
    isVacation: boolean;
};

export const getProjectFormikConfig = (
    onSave: (input: ProjectInput) => Promise<unknown>,
    project?: Project
): FormikConfig<IFormValues> => {
    return {
        initialValues: {
            name: project?.name || '',
            from: project?.from || null,
            to: project?.to || null,
            logo: null,
            logoExtension: '',
            isVacation: project?.isVacation || false,
        },
        validationSchema: yup.object().shape({
            name: yup.string().trim().required('Укажите название проекта'),
            from: yup
                .date()
                .nullable()
                .required('Укажите дату начала проекта')
                .test({
                    name: 'From required and less then to',
                    test: function (value, { createError, parent }) {
                        if (parent.to && parent.to.getTime() < value.getTime()) {
                            return createError({
                                path: 'to',
                                message: 'Дата завершения проекта должна быть позже даты начала',
                            });
                        }

                        return true;
                    },
                }),
            to: yup.date().required('Укажите дату завершения проекта'),
        }),
        onSubmit: async (values, formikHelpers) => {
            const { name, from, to, logo, logoExtension, isVacation } = values;

            if (!name || !from || !to) return;

            const input = getProjectInput({
                id: project?.id || '',
                name: values.name,
                from,
                to,
                isVacation,
                logo: logo ? { content: await toBase64(logo), extension: logoExtension } : undefined,
            });

            return onSave(input).then(() => formikHelpers.resetForm());
        },
        enableReinitialize: true,
    };
};
