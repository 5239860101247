import { FakeRequest } from '../../api/FakeRequest';
import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';
import { serializer } from '../../dto/utils';
import { EmployeeDetails } from '../../dto/EmployeeDetailsDto';

export const employeeDetailsRequest = !mockApi
    ? new HttpRequest<{ id: string }, EmployeeDetails>({
          method: 'get',
          path: ({ id }) => `/employee/${id}/workload`,
          mapper: data => serializer.deserializeObject(data, EmployeeDetails) as EmployeeDetails,
      })
    : new FakeRequest<{ id: string }, EmployeeDetails>({
          fakeData: ({ id }) =>
              serializer.deserializeObject(
                  {
                      id,
                      name: 'Jane',
                      surname: 'Doe',
                      hasPhoto: false,
                      workload: [
                          {
                              id: '1',
                              project: {
                                  id: '1',
                                  name: 'Project 1',
                                  from: '01.01.2024',
                                  to: '31.10.2024',
                                  hasLogo: false,
                              },
                              from: '01.01.2024',
                              to: '31.10.2024',
                              percent: 0.8,
                              comment: 'Исследование',
                          },
                          {
                              id: '2',
                              project: {
                                  id: '1',
                                  name: 'Project 1',
                                  from: '01.01.2024',
                                  to: '31.10.2024',
                                  hasLogo: false,
                              },
                              from: '01.01.2024',
                              to: '31.07.2024',
                              percent: 0.9,
                          },
                      ],
                  },
                  EmployeeDetails
              ) as EmployeeDetails,
      });
