import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { Box, LinearProgress, styled, Typography } from '@mui/material';
import { projectDetailsStore } from '../../stores/projectDetailsStore';
import { observer } from 'mobx-react';
import { ProjectDetails } from './ProjectDetails';
import { Layout } from '../../components/Layout';

const Root = styled(Box)({
    width: '100%',
});

export const ProjectDetailsPage: FC = observer(() => {
    const { projectId } = useParams();

    useEffect(() => {
        projectDetailsStore.refresh(projectId || '');
    }, [projectId]);

    const data = projectDetailsStore.data;

    return (
        <Layout title={data?.name || ''}>
            <Root>
                {projectDetailsStore.error && (
                    <Typography color="error">{projectDetailsStore.error.message}</Typography>
                )}
                {projectDetailsStore.isLoading && <LinearProgress />}
                {data && <ProjectDetails data={data} />}
            </Root>
        </Layout>
    );
});
