import React, { FC, useCallback, useState } from 'react';
import { Employee } from '../../dto';
import { Box, styled } from '@mui/material';
import { EmployeeForm } from './EmployeeForm';
import { EmployeeInfo } from '../../components/EmployeeInfo';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react';
import { useMobileLayout } from '../../hooks';

type Props = {
    employee: Employee;
    onArchive?: () => void;
    onRestore?: () => void;
    clickable?: boolean;
    disabled?: boolean;
    tags: {
        overload: boolean;
        availableSoon: boolean;
        onVacation: boolean;
    };
};

const Root = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
});

type ContainerProps = {
    clickable: boolean;
    archived: boolean;
    active: boolean;
};

const Container = styled('div')<ContainerProps>(({ theme, archived, clickable, active }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    background: archived ? '#CCCCCC' : active ? '#EEF5EE' : '#EEEEEE',
    padding: theme.spacing(2),
    cursor: clickable ? 'pointer' : 'inherit',
}));

const overloadLabel = {
    label: 'Высокая загрузка',
    back: 'crimson',
    color: 'white',
};

const availableLabel = {
    label: 'Скоро освободится',
    back: 'palegreen',
};

const vacationLabel = {
    label: 'Отпуск',
    back: 'lavender',
};

export const EmployeeCard: FC<Props> = observer(
    ({ employee, onArchive, onRestore, clickable = false, disabled = false, tags }) => {
        const [open, setOpen] = useState<boolean>(false);

        const [active, setActive] = useState<boolean>(false);

        const navigate = useNavigate();

        const isMobile = useMobileLayout();

        const onClick = useCallback(() => {
            if (!clickable) return;

            navigate(`/employee/${employee.id}`);
        }, [employee, navigate, clickable]);

        const labels = [];
        if (tags?.availableSoon) labels.push(availableLabel);
        if (tags?.overload) labels.push(overloadLabel);
        if (tags?.onVacation) labels.push(vacationLabel);

        return (
            <Root>
                <Container
                    onMouseEnter={() => setActive(true)}
                    onMouseLeave={() => setActive(false)}
                    onClick={onClick}
                    archived={employee.isArchived}
                    clickable={clickable && !disabled}
                    active={active}
                >
                    <EmployeeInfo
                        employee={employee}
                        onEdit={() => setOpen(true)}
                        showButtons={active || isMobile}
                        onArchive={() => (onArchive ? onArchive() : undefined)}
                        onRestore={() => (onRestore ? onRestore() : undefined)}
                        disabled={disabled}
                        labels={labels}
                    />
                </Container>
                <EmployeeForm employee={employee} open={open} onClose={() => setOpen(false)} />
            </Root>
        );
    }
);
