import React, { FC } from 'react';
import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { planningModeStore } from '../../stores/planningModeStore';
import { observer } from 'mobx-react';
import { PercentOutlined, WatchLaterOutlined } from '@mui/icons-material';
import { useMobileLayout } from '../../hooks';

export const PlanningModeSwitch: FC = observer(() => {
    const ContrastToggleButton = styled(ToggleButton)({
        '&.Mui-selected': {
            backgroundColor: '#FFFFFF33',
            color: 'white',
            borderColor: '#FFFFFF33',
        },
        color: '#FFFFFFCC',
        borderColor: '#FFFFFF33',
    });

    const isMobile = useMobileLayout();

    return (
        <ToggleButtonGroup
            value={planningModeStore.mode}
            exclusive
            onChange={(_e, newMode) => planningModeStore.setMode(newMode)}
        >
            <ContrastToggleButton value={'percents'} disabled={planningModeStore.mode === 'percents'}>
                {!isMobile ? 'Проценты' : <PercentOutlined />}
            </ContrastToggleButton>
            <ContrastToggleButton value={'hours'} disabled={planningModeStore.mode === 'hours'}>
                {!isMobile ? ' Часы' : <WatchLaterOutlined />}
            </ContrastToggleButton>
        </ToggleButtonGroup>
    );
});
