import { FC, ReactNode } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    styled,
    Typography,
} from '@mui/material';

type Props = {
    title: string;
    message: string;
    info?: string;
    open: boolean;
    onClose: (result: boolean) => void;
    icon?: ReactNode;
};

const ActionButton = styled(Button)({
    minWidth: '80px',
});

const Content = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}));

export const ConfirmationDialog: FC<Props> = ({ title, message, info, open, onClose, icon }) => {
    return (
        <Dialog open={open} maxWidth="md">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Content>
                    <Typography>{message}</Typography>
                    {info && (
                        <Typography variant="body2" color="textDisabled">
                            {info}
                        </Typography>
                    )}
                </Content>
            </DialogContent>
            <Divider />
            <DialogActions>
                <ActionButton variant="outlined" onClick={() => onClose(false)}>
                    Отмена
                </ActionButton>
                <ActionButton variant="contained" onClick={() => onClose(true)} endIcon={icon}>
                    OK
                </ActionButton>
            </DialogActions>
        </Dialog>
    );
};
