import { Check, Remove } from '@mui/icons-material';
import React, { FC } from 'react';

type Props = {
    value: boolean;
};

export const BooleanField: FC<Props> = ({ value }) => {
    return value ? <Check /> : <Remove />;
};
