import React, { FC, useCallback, useMemo } from 'react';
import { styled, Tooltip, Typography } from '@mui/material';
import { getEmployeePhotoUrl } from '../../utils/employee';
import { formatFullName, formatInitials } from '../../utils/formatters';
import { useNavigate } from 'react-router';
import { DisplayEmployee, Size } from '../../utils/types';

type Props = {
    employee: DisplayEmployee;
    size?: Size;
    clickable?: boolean;
    disabled?: boolean;
};

type RootProps = {
    clickable?: boolean;
    iconSize: Size;
};

const sizes = new Map<Size, number>([
    ['small', 20],
    ['normal', 40],
]);
const borderWidth = new Map<Size, number>([
    ['small', 1],
    ['normal', 3],
]);

const Root = styled('div')<RootProps>(({ theme, clickable, iconSize }) => ({
    borderRadius: '50%',
    minWidth: `${sizes.get(iconSize)}px`,
    minHeight: `${sizes.get(iconSize)}px`,
    maxWidth: `${sizes.get(iconSize)}px`,
    maxHeight: `${sizes.get(iconSize)}px`,
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: clickable ? 'pointer' : 'inherit',
    border: `${borderWidth.get(iconSize)}px solid ${theme.palette.primary.main}`,
}));

type PortraitProps = {
    iconSize: Size;
};

const Portrait = styled('img')<PortraitProps>(({ theme, iconSize }) => ({
    minWidth: `${sizes.get(iconSize)}px`,
    minHeight: `${sizes.get(iconSize)}px`,
    maxWidth: `${sizes.get(iconSize)}px`,
    maxHeight: `${sizes.get(iconSize)}px`,
    objectFit: 'cover',
    borderRadius: '50%',
    border: `${borderWidth.get(iconSize)}px solid ${theme.palette.primary.main}`,
}));

export const EmployeeBadge: FC<Props> = ({ employee, clickable = true, disabled = false, size = 'normal' }) => {
    const initials = useMemo(() => formatInitials(employee), [employee]);

    const navigate = useNavigate();

    const navigateToEmployee = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();

            navigate(`/employee/${employee.id}`);
        },
        [employee, navigate]
    );

    return (
        <Tooltip title={`${formatFullName(employee)}`}>
            <Root
                onClick={clickable && !disabled ? navigateToEmployee : undefined}
                clickable={clickable}
                iconSize={size}
            >
                {employee.hasPhoto ? (
                    <Portrait src={getEmployeePhotoUrl(employee)} alt={initials} iconSize={size} />
                ) : (
                    <Typography variant={size === 'normal' ? 'body1' : 'caption'}>{initials}</Typography>
                )}
            </Root>
        </Tooltip>
    );
};
