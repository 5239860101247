import React, { FC, useCallback, useEffect, useState } from 'react';
import {
    Button,
    IconButton,
    LinearProgress,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { NoDataPlaceholder } from '../../components/NoDataPlaceholder';
import { formatDate } from '../../utils/formatters';
import { Block, BlockRounded, Check, CheckCircle, Key } from '@mui/icons-material';
import { usersStore } from '../../stores/usersStore';
import { observer } from 'mobx-react';
import { approveUserRequest } from './approveUserRequest';
import { messageStore } from '../../stores/messageStore';
import { ConfirmationDialog } from '../../components/ConfirmationDialog';
import { blockUserRequest } from './blockUserRequest';
import { unblockUserRequest } from './unblockUserRequest';
import { BooleanField } from '../../components/BooleanField';
import { useMobileLayout } from '../../hooks';
import { UserStatusBadge } from '../../components/UserStatusBadge';
import { UserStatus } from '../../dto';

const UserTableContainer = styled(TableContainer)({
    overflowY: 'auto',
    overflowX: 'auto',
    height: '100%',
    width: '100%',
});

export const AdminUsers: FC = observer(() => {
    useEffect(() => {
        usersStore.refresh();
    }, []);

    const [activeBlocked, setActiveBlocked] = useState<string | null>(null);
    const [activeVerified, setActiveVerified] = useState<string | null>(null);
    const [verifyId, setVerifyId] = useState<string | null>(null);
    const [blockId, setBlockId] = useState<string | null>(null);
    const [unblockId, setUnblockId] = useState<string | null>(null);

    const isMobile = useMobileLayout();

    const onVerifyAnswer = useCallback(
        (answer: boolean) => {
            if (!verifyId) return;

            setVerifyId(null);

            if (!answer) return;

            approveUserRequest
                .request({ id: verifyId })
                .then(() => messageStore.success('Пользователь верифицирован'))
                .then(usersStore.refresh);
        },
        [verifyId]
    );

    const onBlockAnswer = useCallback(
        (answer: boolean) => {
            if (!blockId) return;

            setBlockId(null);

            if (!answer) return;

            blockUserRequest
                .request({ id: blockId })
                .then(() => messageStore.success('Пользователь заблокирован'))
                .then(usersStore.refresh);
        },
        [blockId]
    );

    const onUnblockAnswer = useCallback(
        (answer: boolean) => {
            if (!unblockId) return;

            setUnblockId(null);

            if (!answer) return;

            unblockUserRequest
                .request({ id: unblockId })
                .then(() => messageStore.success('Пользователь разблокирован'))
                .then(usersStore.refresh);
        },
        [unblockId]
    );

    if (usersStore.isLoading) return <LinearProgress />;

    if (usersStore.error) return <Typography color="error">{usersStore.error.message}</Typography>;

    if (usersStore.data?.length === 0) return <NoDataPlaceholder />;

    return (
        <>
            <UserTableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Пользователь</TableCell>
                            <TableCell>Администратор</TableCell>
                            <TableCell>Состояние</TableCell>
                            <TableCell>Блокировка</TableCell>
                            <TableCell>Создан</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(usersStore.data || []).map(item => (
                            <TableRow key={item.id}>
                                <TableCell>{item.email}</TableCell>
                                <TableCell align="center">
                                    <BooleanField value={item.isAdmin} />
                                </TableCell>
                                <TableCell
                                    onMouseOver={() => setActiveVerified(item.id)}
                                    onMouseLeave={() => setActiveVerified(null)}
                                    align="center"
                                >
                                    {isMobile ? (
                                        <Stack direction="row" alignItems="center">
                                            <UserStatusBadge status={item.status} size="small" />
                                            {item.status === UserStatus.NOT_APPROVED && (
                                                <IconButton onClick={() => setVerifyId(item.id)} color="primary">
                                                    <CheckCircle />
                                                </IconButton>
                                            )}
                                        </Stack>
                                    ) : item.status === UserStatus.NOT_APPROVED && item.id === activeVerified ? (
                                        <Button size="small" onClick={() => setVerifyId(item.id)}>
                                            Верифицировать
                                        </Button>
                                    ) : (
                                        <UserStatusBadge status={item.status} />
                                    )}
                                </TableCell>
                                <TableCell
                                    onMouseOver={() => setActiveBlocked(item.id)}
                                    onMouseLeave={() => setActiveBlocked(null)}
                                    align="center"
                                >
                                    {isMobile ? (
                                        <IconButton
                                            onClick={() =>
                                                item.isBlocked ? setUnblockId(item.id) : setBlockId(item.id)
                                            }
                                            color="primary"
                                        >
                                            {item.isBlocked ? <Key /> : <BlockRounded />}
                                        </IconButton>
                                    ) : item.id === activeBlocked ? (
                                        <Button
                                            size="small"
                                            onClick={() =>
                                                item.isBlocked ? setUnblockId(item.id) : setBlockId(item.id)
                                            }
                                        >
                                            {item.isBlocked ? 'Разблокировать' : 'Заблокировать'}
                                        </Button>
                                    ) : (
                                        <BooleanField value={item.isBlocked} />
                                    )}
                                </TableCell>
                                <TableCell>{formatDate(item.createdAt)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </UserTableContainer>
            <ConfirmationDialog
                title="Верифицировать?"
                message="Действительно верифицировать пользователя?"
                open={!!verifyId}
                onClose={onVerifyAnswer}
                icon={<Check />}
            />
            <ConfirmationDialog
                title="Заблокировать?"
                message="Действительно заблокировать пользователя?"
                open={!!blockId}
                onClose={onBlockAnswer}
                icon={<Block />}
            />
            <ConfirmationDialog
                title="Разблокировать?"
                message="Действительно разблокировать пользователя?"
                open={!!unblockId}
                onClose={onUnblockAnswer}
                icon={<Key />}
            />
        </>
    );
});
