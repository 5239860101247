import { FakeRequest } from '../../api/FakeRequest';
import { ProjectDetails } from '../../dto';
import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';
import { serializer } from '../../dto/utils';

export const projectDetailsRequest = !mockApi
    ? new HttpRequest<{ id: string }, ProjectDetails>({
          method: 'get',
          path: ({ id }) => `/project/${id}/workload`,
          mapper: data => serializer.deserializeObject(data, ProjectDetails) as ProjectDetails,
      })
    : new FakeRequest<{ id: string }, ProjectDetails>({
          fakeData: ({ id }) =>
              serializer.deserializeObject(
                  {
                      id,
                      name: 'Project 1',
                      from: '01.01.2024',
                      to: '31.10.2024',
                      hasLogo: false,
                      workload: [
                          {
                              id: '1',
                              employee: {
                                  id: '11',
                                  name: 'Jane',
                                  surname: 'Doe',
                                  hasPhoto: false,
                              },
                              from: '01.01.2024',
                              to: '31.10.2024',
                              percent: 0.8,
                              comment: 'Исследование',
                          },
                          {
                              id: '2',
                              employee: {
                                  id: '12',
                                  name: 'John',
                                  surname: 'Doe',
                                  hasPhoto: false,
                              },
                              from: '01.01.2024',
                              to: '31.07.2024',
                              percent: 0.9,
                          },
                      ],
                  },
                  ProjectDetails
              ) as ProjectDetails,
      });
