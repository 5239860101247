import { mockApi } from '../../../api/constants';
import { HttpRequest } from '../../../api/HttpRequest';
import { WorkloadInput } from '../../../dto';
import { FakeRequest } from '../../../api/FakeRequest';
import { serializer } from '../../../dto/utils';
import { Workload } from '../../../dto/WorkloadDto';

export const updateWorkloadRequest = !mockApi
    ? new HttpRequest<WorkloadInput, Workload>({
          method: 'put',
          path: () => '/workload/',
          mapper: data => serializer.deserializeObject(data, Workload) as Workload,
          body: params => serializer.serializeObject(params) || {},
      })
    : new FakeRequest<WorkloadInput, Workload>({
          fakeData: () =>
              serializer.deserializeObject(
                  {
                      id: '1',
                      from: '01.01.2024',
                      to: '31.10.2024',
                      percent: 0.8,
                      comment: 'Исследование',
                  },
                  Workload
              ) as Workload,
      });
