import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { DEFAULT_DATE, JsonDate, JsonEnum } from './utils';

export enum UserStatus {
    NOT_VERIFIED = 'NOT_VERIFIED',
    NOT_APPROVED = 'NOT_APPROVED',
    APPROVED = 'APPROVED',
}

@JsonObject()
export class User {
    @JsonProperty()
    id: string = '';

    @JsonProperty()
    email: string = '';

    @JsonProperty()
    isAdmin: boolean = false;

    @JsonEnum({ type: UserStatus })
    status: UserStatus = UserStatus.NOT_VERIFIED;

    @JsonProperty()
    isBlocked: boolean = false;

    @JsonDate()
    createdAt: Date = DEFAULT_DATE;
}
