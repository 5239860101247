import { FormikConfig } from 'formik';
import { getEmployeeInput, Employee, EmployeeInput } from '../../../dto';
import * as yup from 'yup';
import { toBase64 } from '../../../utils/files';

export type IFormValues = {
    name: string;
    surname: string;
    patronymic: string;
    photo: File | null;
    photoExtension: string;
};

export const getEmployeeFormikConfig = (
    onSave: (input: EmployeeInput) => Promise<unknown>,
    employee?: Employee
): FormikConfig<IFormValues> => {
    return {
        initialValues: {
            name: employee?.name || '',
            surname: employee?.surname || '',
            patronymic: employee?.patronymic || '',
            photo: null,
            photoExtension: '',
        },
        validationSchema: yup.object().shape({
            name: yup.string().trim().required('Укажите имя'),
            surname: yup.string().trim().required('Укажите фамилию'),
        }),
        onSubmit: async (values, formikHelpers) => {
            const { name, surname, patronymic, photo, photoExtension } = values;

            if (!name || !surname) return;

            const input = getEmployeeInput({
                id: employee?.id || '',
                name,
                surname,
                patronymic: patronymic || undefined,
                photo: photo ? { content: await toBase64(photo), extension: photoExtension } : undefined,
            });

            return onSave(input).then(() => formikHelpers.resetForm());
        },
        enableReinitialize: true,
    };
};
