import { JsonObject, JsonProperty } from 'typescript-json-serializer';

@JsonObject()
export class ProfileInput {
    @JsonProperty()
    password: string = '';

    @JsonProperty()
    confirmPassword: string = '';
}

export const getProfileInput = (input: ProfileInput): ProfileInput => Object.assign(new ProfileInput(), input);
