import moment from 'moment';

export const workDaysIntervalLength = (start: Date, end: Date): number => {
    let result = 0;

    const left = new Date(start <= end ? start : end);
    const right = new Date(start <= end ? end : start);

    let curDate = new Date(left);
    do {
        const dayNumber = moment(curDate).weekday();
        if (![5, 6].includes(dayNumber)) result++;

        curDate = moment(curDate).add(1, 'days').toDate();
    } while (curDate.getTime() <= right.getTime());

    return result;
};
