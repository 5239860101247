import { Chip, styled } from '@mui/material';

type ChipProps = {
    customfontcolor?: string;
    custombackcolor: string;
};

export const ColorChip = styled(Chip)<ChipProps>(({ customfontcolor, custombackcolor }) => ({
    background: custombackcolor,
    color: customfontcolor || 'inherit',
}));
