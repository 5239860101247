import { JsonObject } from 'typescript-json-serializer';
import { JsonEnum } from './utils';

export enum ActivationCodeStatus {
    ACTIVATED = 'ACTIVATED',
    USED = 'USED',
    UNKNOWN = 'UNKNOWN',
}

@JsonObject()
export class ActivationResult {
    @JsonEnum({ type: ActivationCodeStatus })
    status: ActivationCodeStatus = ActivationCodeStatus.UNKNOWN;
}
