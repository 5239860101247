import React, { FC, useEffect, useState } from 'react';
import { LinearProgress, Paper, styled, Typography } from '@mui/material';
import { Layout } from '../../components/Layout';
import { authStore } from '../../stores/authStore';
import { ActivationCodeStatus } from '../../dto';
import { activateRequest } from './activateRequest';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';

const Root = styled(Paper)(({ theme }) => ({
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5),
    },
}));

const title = 'Активация аккаунта';

export const ActivatePage: FC = observer(() => {
    const [params, _setParams] = useSearchParams();

    const code = params.get('code') || '';

    const [result, setResult] = useState<ActivationCodeStatus | null>(null);

    const [error, setError] = useState(false);

    const needProcess = code && !authStore.isLoggedIn;

    useEffect(() => {
        if (!needProcess) return;

        activateRequest
            .request({ code })
            .then(value => setResult(value.status))
            .catch(() => setError(true));
    }, [code, needProcess, setResult]);

    if (!needProcess)
        return (
            <Layout title={title} actions={authStore.isLoggedIn ? 'full' : 'none'} showSelector={false}>
                <Root>
                    <Typography>
                        Для активации аккаунта не должно быть активной сессии и должен быть указан код активации.
                    </Typography>
                </Root>
            </Layout>
        );

    if (activateRequest.isLoading)
        return (
            <Layout title={title} actions={'none'} showSelector={false}>
                <Root>
                    <LinearProgress />
                </Root>
            </Layout>
        );

    if (!result || result === ActivationCodeStatus.UNKNOWN || error)
        return (
            <Layout title={title} actions={'none'} showSelector={false}>
                <Root>
                    <Root>
                        <Typography color="error">Не удалось активировать учетную запись.</Typography>
                    </Root>
                </Root>
            </Layout>
        );

    return (
        <Layout title={title} actions={'none'} showSelector={false}>
            <Root>
                <Typography>
                    Учетная запись успешно активирована. Дождитесь верификации аккаунта администратором, чтобы начать
                    работу.
                </Typography>
            </Root>
        </Layout>
    );
});
