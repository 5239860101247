import { getProfileInput, ProfileInput } from '../../dto';
import { FormikConfig } from 'formik';
import * as yup from 'yup';
import { MIN_PASSWORD_LENGTH } from '../../utils/user';

export type IFormValues = {
    password: string;
    confirmPassword: string;
};

export const getProfileFormik = (onUpdate: (input: ProfileInput) => Promise<unknown>): FormikConfig<IFormValues> => {
    return {
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: yup.object().shape({
            password: yup
                .string()
                .trim()
                .required('Укажите пароль')
                .min(MIN_PASSWORD_LENGTH, 'Слишком короткий пароль'),
            confirmPassword: yup
                .string()
                .trim()
                .required('Укажите подтверждение пароля')
                .test({
                    name: 'Passwords should match',
                    test: function (value, { createError, parent }) {
                        if (value !== parent.password) {
                            return createError({
                                path: 'confirmPassword',
                                message: 'Пароли не совпадают',
                            });
                        }

                        return true;
                    },
                }),
        }),
        onSubmit: async (values: IFormValues, formikHelpers) => {
            const { password, confirmPassword } = values;

            if (!password || !confirmPassword) {
                return;
            }

            return onUpdate(getProfileInput({ password, confirmPassword })).then(() => formikHelpers.resetForm());
        },
    };
};
