import { FormikContextType } from 'formik';
import { Button, colors, FormControl, FormLabel, IconButton, styled, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownLoadIcon from '@mui/icons-material/Download';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { last } from 'lodash';

const selectButtonStyle: React.CSSProperties = {
    width: 180,
    textTransform: 'none',
    whiteSpace: 'nowrap',
    color: colors.grey[600],
    borderColor: colors.grey[600],
    marginRight: 12,
};

const RootFormControl = styled(FormControl)({
    width: '100%',
});

const Img = styled('img')(() => ({
    width: 40,
    height: 40,
    marginRight: 16,
}));

type FormikFileFieldProps<
    TValues extends {},
    TFieldName extends keyof TValues & string,
    TFieldNameExt extends keyof TValues & string,
> = {
    name: TValues[TFieldName] extends File | null ? TFieldName : never;
    extensionName: TValues[TFieldNameExt] extends string ? TFieldNameExt : never;
    formik: FormikContextType<TValues>;
    label?: string;
    required?: boolean;
    disabled?: boolean;
    onDownload?: () => void;
    hasFile?: boolean;
};

const Label = styled(FormLabel)(({ theme }) => ({
    color: theme.palette.grey[800],
    fontSize: '0.8em',
    fontWeight: 500,
}));

export const FormikFileField = <
    TValues extends {},
    TFieldName extends keyof TValues & string,
    TFieldNameExt extends keyof TValues & string,
>({
    label,
    name,
    extensionName,
    formik,
    required,
    disabled,
    onDownload,
    hasFile = false,
}: FormikFileFieldProps<TValues, TFieldName, TFieldNameExt>) => {
    const value = formik.values[name] as File | null;
    const error = formik.errors[name]?.toString();
    const touched = formik.touched[name];

    const [src, setSrc] = useState('');

    useEffect(() => {
        if (value) {
            const url = URL.createObjectURL(value);
            setSrc(url);

            return () => URL.revokeObjectURL(url);
        } else {
            setSrc('');
        }
    }, [value]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            const file = e.target.files[0]!;

            e.target.value = '';
            formik.setFieldValue(name, file, true).then(() => formik.setFieldTouched(name, true));
            formik
                .setFieldValue(extensionName, last(file.name.split('.')), true)
                .then(() => formik.setFieldTouched(name, true));
        }
    };

    const handleClear = () => {
        formik.setFieldValue(name, null, true);
        formik.setFieldValue(extensionName, null, true);
        formik.setFieldTouched(name, true);
        formik.setFieldTouched(extensionName, true);
    };

    const hasFormikError = !!touched && !!error;
    const formikError = touched ? error : undefined;

    return (
        <RootFormControl>
            <Label color="primary">{label}</Label>
            <TextField
                required={required}
                variant="outlined"
                slotProps={{
                    input: value
                        ? {
                              startAdornment: <Img alt="preview" src={src} />,
                              endAdornment: (
                                  <IconButton onClick={handleClear} disabled={disabled}>
                                      <CloseIcon />
                                  </IconButton>
                              ),
                          }
                        : {
                              startAdornment: disabled ? undefined : (
                                  <Button style={selectButtonStyle} variant="outlined" component="label">
                                      + Выбрать файл
                                      <input type="file" accept="image" hidden onChange={handleChange} />
                                  </Button>
                              ),
                              endAdornment: !!onDownload ? (
                                  <IconButton onClick={onDownload}>
                                      <DownLoadIcon />
                                  </IconButton>
                              ) : undefined,
                          },
                    inputLabel: { shrink: true, style: { whiteSpace: 'normal' } },
                }}
                value={value ? value.name : hasFile ? 'Загружен' : ''}
                disabled={!!value || disabled}
                error={value ? hasFormikError : undefined}
                helperText={value ? formikError : undefined}
            />
        </RootFormControl>
    );
};
