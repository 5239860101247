import { mockApi } from '../api/constants';
import { HttpRequest } from '../api/HttpRequest';
import { User } from '../dto';
import { serializer } from '../dto/utils';
import { FakeRequest } from '../api/FakeRequest';

export const userRequest = !mockApi
    ? new HttpRequest<{}, User>({
          method: 'get',
          path: () => '/user/me',
          mapper: data => serializer.deserializeObject(data, User) as User,
      })
    : new FakeRequest<{}, User>({
          fakeData: () =>
              serializer.deserializeObject(
                  {
                      id: '1',
                      name: 'User',
                  },
                  User
              ) as User,
      });
