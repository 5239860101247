import React, { FC, useEffect } from 'react';
import { HomePage } from './views/HomePage';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme/theme';
import { RouteObject, RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import { ProjectDetailsPage } from './views/ProjectDetailsPage';
import { MessageProvider } from './components/MessageProvider';
import { ProjectsPage } from './views/ProjectsPage';
import { EmployeesPage } from './views/EmployeesPage';
import { EmployeeDetailsPage } from './views/EmployeeDetailsPage';
import { LoginPage } from './views/LoginPage';
import { RegisterPage } from './views/RegisterPage';
import { authStore } from './stores/authStore';
import { observer } from 'mobx-react';
import { refreshUser } from './utils/user';
import { ProfilePage } from './views/ProfilePage';
import { AdminPage } from './views/AdminPage';
import { NotActivePage } from './views/NotActivePage';
import { ActivatePage } from './views/ActivatePage';
import { RequestResetPasswordPage } from './views/RequestResetPasswordPage';
import { ResetPasswordPage } from './views/ResetPasswordPage';

const registerRouter = createBrowserRouter([
    {
        path: '/register',
        element: <RegisterPage />,
    },
    {
        path: '/activation',
        element: <ActivatePage />,
    },
    {
        path: '/request-reset',
        element: <RequestResetPasswordPage />,
    },
    {
        path: '/reset-password',
        element: <ResetPasswordPage />,
    },
    {
        path: '/*',
        element: <LoginPage />,
    },
]);

const notActiveRouter = createBrowserRouter([
    {
        path: '/activation',
        element: <ActivatePage />,
    },
    {
        path: '/*',
        element: <NotActivePage />,
    },
]);

export const App: FC = observer(() => {
    useEffect(() => {
        if (authStore.isLoggedIn) refreshUser().catch(authStore.logout);
    }, []);

    const routerItems: RouteObject[] = [
        {
            path: '/project',
            element: <ProjectsPage />,
        },
        {
            path: '/projects/:projectId',
            element: <ProjectDetailsPage />,
        },
        {
            path: '/employee',
            element: <EmployeesPage />,
        },
        {
            path: '/employee/:employeeId',
            element: <EmployeeDetailsPage />,
        },
        {
            path: '/profile',
            element: <ProfilePage />,
        },
        {
            path: '/activation',
            element: <ActivatePage />,
        },
        {
            path: '/reset-password',
            element: <ResetPasswordPage />,
        },
    ];

    if (authStore.isAdmin) {
        routerItems.push({
            path: '/admin',
            element: <AdminPage />,
        });
    }

    routerItems.push({
        path: '/*',
        element: <HomePage />,
    });

    const router = createBrowserRouter(routerItems);

    return (
        <ThemeProvider theme={theme}>
            <MessageProvider />
            {authStore.isLoggedIn ? (
                authStore.canAccess ? (
                    <RouterProvider router={router} />
                ) : (
                    <RouterProvider router={notActiveRouter} />
                )
            ) : (
                <RouterProvider router={registerRouter} />
            )}
        </ThemeProvider>
    );
});
